// client/src/pages/PetRelocation.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * PetRelocation page. Uses a ThreeColumnLayout to show:
 *  - Left column: StepHeader (title="Pets", stepNumber=4)
 *  - Middle column: Pet relocation budget
 *  - Right column: SavedRelocationPlans (via the layout)
 *
 * Props from App.js:
 *  - relocationPlans (array): Fetched once in App.js
 *  - selectedPlanId (string): The currently selected plan
 *  - setSelectedPlanId (func): Updates the selected plan
 */
function PetRelocation({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch pet relocation budget once on mount
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        // Suppose the server response has { petRelocation: { crates, vet, quarantine } }
        setBudget(response.data?.petRelocation);
      })
      .catch((err) => {
        console.error('Error fetching pet relocation budget:', err);
      });
  }, []);

  return (
    <ThreeColumnLayout
      title="Pets"
      stepNumber={5}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/pet-relocation"
    >
      {/* MIDDLE COLUMN CONTENT ONLY */}
      <CategoryCard title="Pet Relocation">
        {budget && (
          <ul className="space-y-2">
            <li>Crates: ${budget.crates}</li>
            <li>Vet: ${budget.vet}</li>
            <li>Quarantine: ${budget.quarantine}</li>
          </ul>
        )}
      </CategoryCard>
    </ThreeColumnLayout>
  );
}

export default PetRelocation;
