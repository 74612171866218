// client/src/components/profileModalTabsElements/MovingStrategyTab.js
import React from 'react';

function MovingStrategyTab({ movingStrategy, setMovingStrategy, handleToggleTask }) {
  return (
    <div>
      <ul className="space-y-2 mb-4">
        {movingStrategy.length > 0 ? (
          movingStrategy.map((task) => (
            <li key={task.id} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={task.completed}
                onChange={() => handleToggleTask(task.id)}
                className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
              />
              <span className={task.completed ? 'line-through text-gray-500' : ''}>
                {task.task}
              </span>
            </li>
          ))
        ) : (
          <p>No tasks in your moving strategy yet.</p>
        )}
      </ul>
    </div>
  );
}

export default MovingStrategyTab;