// client/src/components/MyToDoList.js
import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import api from '../services/api';

function MyToDoList({ userTodos, activeTodo, onToggleComplete, onSelectTodo }) {
  // Fetch default to-dos to find the correct emoji
  const [defaultTodos, setDefaultTodos] = useState([]);

  useEffect(() => {
    fetchDefaultTodos();
  }, []);

  async function fetchDefaultTodos() {
    try {
      const resp = await api.get('/todo');
      setDefaultTodos(resp.data || []);
    } catch (err) {
      console.error('Error fetching default todos:', err);
    }
  }

  // Return the emoji for the item’s todo_id, or "🚚" if custom
  function getEmoji(item) {
    if (item.todo_id) {
      const found = defaultTodos.find((d) => d.id === item.todo_id);
      if (found) return found.emoji;
    }
    // Fallback => "Moving" emoji
    return '🚚';
  }

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <div className="border-2 rounded-2xl p-4">
          <Disclosure.Button className="flex w-full justify-between items-center p-4 text-gray-700 hover:text-gray-900">
            <h3 className="text-lg font-bold">My To-Do List</h3>
          </Disclosure.Button>

          <Disclosure.Panel className="px-4 pb-4">
            {(!userTodos || userTodos.length === 0) ? (
              <p className="text-gray-500">No to-do items for this plan.</p>
            ) : (
              userTodos.map((item) => {
                const isEditing = activeTodo?.id === item.id;
                const theEmoji = getEmoji(item);

                return (
                  <Disclosure key={item.id}>
                    {({ open: itemOpen }) => {
                      // If item is open or in edit mode => no grayscale on emoji
                      const emojiFilter = (itemOpen || isEditing)
                        ? 'none'
                        : 'grayscale(100%)';

                      // Text classes:
                      // - line-through if completed
                      // - green if editing, else turn green on hover
                      const textClasses = [
                        item.is_completed ? 'line-through text-gray-400' : '',
                        isEditing ? 'text-green-600' : 'hover:text-green-600'
                      ]
                        .filter(Boolean)
                        .join(' ');

                      // Description classes => also green if editing, else gray
                      const descClasses = isEditing
                        ? 'text-green-600'
                        : 'text-gray-600 hover:text-green-600';

                      return (
                        <div
                          key={item.id}
                          className={`mb-2 border-b last:border-b-0 
                            ${isEditing ? 'bg-gray-50' : ''}`}
                          onDoubleClick={() => onToggleComplete(item)}
                          title="Double-click to toggle completion"
                        >
                          <Disclosure.Button
                            onClick={() => onSelectTodo(item)}
                            className="flex w-full justify-between  py-2 items-center text-gray-700"
                          >
                            <div className="flex items-center space-x-2">
                              {/* Date first */}
                              {item.planned_date && (
                                <span className="text-sm font-semibold text-gray-700 min-w-[6rem]">
                                  {item.planned_date}
                                </span>
                              )}
                              {/* Action text */}
                              <span className= {`font-light ${textClasses}`}>
                                {item.custom_action}
                              </span>
                            </div>

                            {/* Emoji instead of chevron */}
                            <span style={{ filter: emojiFilter }}>
                              {theEmoji}
                            </span>
                          </Disclosure.Button>

                          <Disclosure.Panel className={`px-4 font-light text-gray-400 pb-2 ${descClasses}`}>
                            {item.description || <em>No description</em>}
                          </Disclosure.Panel>
                        </div>
                      );
                    }}
                  </Disclosure>
                );
              })
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}

export default MyToDoList;
