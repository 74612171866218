// client/src/pages/Budget.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * Budget page. Uses ThreeColumnLayout to display:
 *  - Left column: StepHeader (title="Budget", stepNumber=9) + SavedRelocationPlans
 *  - Middle column: Budget breakdown
 *  - Right column: (optional)
 *
 * Props:
 *  - relocationPlans (array)
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 */
function Budget({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch overall budget info once
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        setBudget(response.data);
      })
      .catch((err) => {
        console.error('Error fetching budget data:', err);
      });
  }, []);

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Budget Breakdown">
      {budget && (
        <ul className="space-y-2">
          <li>Rent Termination: ${budget.rentTermination}</li>
          <li>Packing Materials: ${budget.packingMaterials}</li>
          <li>Storage: ${budget.storage}</li>
          <li>Transportation: ${budget.transportation}</li>

          <li>
            Travel Costs:
            <ul className="ml-4 space-y-1">
              <li>Tickets: ${budget.travel.tickets}</li>
              <li>Fuel: ${budget.travel.fuel}</li>
              <li>Luggage: ${budget.travel.luggage}</li>
              <li>Temp Accommodation: ${budget.travel.tempAccommodation}</li>
              <li>Car Rental: ${budget.travel.carRental}</li>
            </ul>
          </li>

          <li>
            Legal Docs:
            <ul className="ml-4 space-y-1">
              <li>Visas: ${budget.legalDocs.visas}</li>
              <li>Permits: ${budget.legalDocs.permits}</li>
              <li>Address Change: ${budget.legalDocs.addressChange}</li>
            </ul>
          </li>

          <li>
            Pet Relocation:
            <ul className="ml-4 space-y-1">
              <li>Crates: ${budget.petRelocation.crates}</li>
              <li>Vet: ${budget.petRelocation.vet}</li>
              <li>Quarantine: ${budget.petRelocation.quarantine}</li>
            </ul>
          </li>

          <li>
            Insurance:
            <ul className="ml-4 space-y-1">
              <li>Moving: ${budget.insurance.moving}</li>
              <li>Travel: ${budget.insurance.travel}</li>
              <li>Health: ${budget.insurance.health}</li>
            </ul>
          </li>

          <li>
            Settling In:
            <ul className="ml-4 space-y-1">
              <li>Groceries: ${budget.settlingIn.groceries}</li>
              <li>Furniture: ${budget.settlingIn.furniture}</li>
              <li>Internet: ${budget.settlingIn.internet}</li>
            </ul>
          </li>
        </ul>
      )}
    </CategoryCard>
  );

  // If you want something in the right column, define rightColumnContent
  // For now, we'll leave it null.

  return (
    <ThreeColumnLayout
      title="Budget"
      stepNumber={10}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/budget"
      centerColumn={centerColumnContent}
      // rightColumn={someRightColumnContent}
    />
  );
}

export default Budget;
