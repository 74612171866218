import React from 'react';

function CategoryCard({ title, children }) {
  return (
    <div className="bg-white p-6 rounded-lg border mb-4">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
      {children}
    </div>
  );
}

export default CategoryCard;