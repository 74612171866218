// client/src/pages/Travel.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * Travel page. Uses ThreeColumnLayout to display:
 *  - Left column: StepHeader (title="Travel", stepNumber=7) + SavedRelocationPlans
 *  - Middle column: CategoryCard with budget details
 *  - Right column: (optional) empty or some other content
 *
 * Props (passed from App.js):
 *  - relocationPlans (array)
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 */
function Travel({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch any relevant budget info for travel
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        // Example structure: { travel: { visas, permits, addressChange } }
        setBudget(response.data?.travel);
      })
      .catch((err) => {
        console.error('Error fetching travel budget:', err);
      });
  }, []);

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Travel">
      {budget && (
        <ul className="space-y-2">
          <li>Visas: ${budget.visas}</li>
          <li>Permits: ${budget.permits}</li>
          <li>Address Change: ${budget.addressChange}</li>
        </ul>
      )}
    </CategoryCard>
  );

  // Optionally define a right column if needed, e.g.:
  // const rightColumnContent = <SomeStatsComponent />;
  // For now, we'll leave it empty (null).

  return (
    <ThreeColumnLayout
      title="Travel"
      stepNumber={8}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/travel"
      centerColumn={centerColumnContent}
      // rightColumn={rightColumnContent} // if you want something in the right column
    />
  );
}

export default Travel;
