// client/src/components/Navigation.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

function Navigation({ currentStep, totalSteps, steps }) {
  const navigate = useNavigate();

  const handleStepClick = (stepIndex, path) => {
    navigate(path);
  };

  return (
    <div className="flex items-center justify-center my-2 ">
      {/* This container is scrollable on small screens */}
      <div className="w-full max-w-7xl overflow-x-auto">
        {/* 
          We use 'justify-start' so steps line up from left to right 
          and keep the horizontal lines a fixed width so they're always visible.
        */}
        <div className="flex items-center justify-start whitespace-nowrap">
          {steps.map((step, index) => {
            const isActive = index === currentStep;

            return (
              <div key={index} className="flex items-center min-w-max">
                <button
                  onClick={() => handleStepClick(index, step.path)}
                  className={`
                    group relative w-10 h-10 flex items-center justify-center
                    rounded-full border-2 border-gray-300 bg-white text-gray-500
                    hover:bg-gray-50 disabled:opacity-50 transition-colors duration-200
                    ${isActive ? ' text-green-500  border-green-400' : ''}
                  `}
                  title={step.label}
                >
                  {/* Default: show the step number */}
                  <span
                    className={`
                      absolute inset-0 flex items-center justify-center
                      transition-opacity duration-200
                      ${isActive ? '' : 'text-gray-500'}
                      group-hover:opacity-0
                    `}
                  >
                    {index + 1}
                  </span>

                  {/* Hover icon (ArrowRight) fades in on hover */}
                  <ArrowRightIcon
                    className={`
                      absolute inset-0 h-5 w-5 m-auto
                      transition-opacity duration-200
                      opacity-0 group-hover:opacity-100
                      ${isActive ? 'text-green-600' : 'text-gray-500'}
                    `}
                  />
                </button>

                {/* 
                  Connector line between steps: 
                  we use a fixed width (e.g. w-8) 
                  so it's always visible.
                */}
                {index < totalSteps - 1 && (
                  <div
                    className={`h-1 w-12 sm:w-20 mx-2 ${
                      index < currentStep ? 'bg-green-600' : 'bg-gray-300'
                    }`}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
