import React, { useState } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';

function MovingStrategies() {
  const [formData, setFormData] = useState({
    destinationType: '',
    familySize: '',
    recommendedMoveType: '',
    budgetEstimate: '',
  });
  const [strategies, setStrategies] = useState([]);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/strategy', {
        ...formData,
        familySize: parseInt(formData.familySize),
        budgetEstimate: parseFloat(formData.budgetEstimate),
      });
      setStrategies([...strategies, response.data]);
      setFormData({ destinationType: '', familySize: '', recommendedMoveType: '', budgetEstimate: '' });
      setError(null);
    } catch (error) {
      console.error('Error creating strategy:', error);
      setError(error.response?.data?.error || 'Failed to create strategy');
    }
  };

  return (
    <CategoryCard title="Moving Strategies">
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          name="destinationType"
          value={formData.destinationType}
          onChange={handleChange}
          placeholder="Destination Type"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
          required
        />
        <input
          name="familySize"
          value={formData.familySize}
          onChange={handleChange}
          placeholder="Family Size"
          type="number"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
          required
        />
        <input
          name="recommendedMoveType"
          value={formData.recommendedMoveType}
          onChange={handleChange}
          placeholder="Recommended Move Type"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
          required
        />
        <input
          name="budgetEstimate"
          value={formData.budgetEstimate}
          onChange={handleChange}
          placeholder="Budget Estimate"
          type="number"
          step="0.01"
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
          required
        />
        <button
          type="submit"
          className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Add Strategy
        </button>
      </form>
      {strategies.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Your Strategies:</h3>
          <ul className="space-y-2">
            {strategies.map((strategy, index) => (
              <li key={index}>
                {strategy.destination_type} - {strategy.family_size} - {strategy.recommended_move_type} - ${strategy.budget_estimate}
              </li>
            ))}
          </ul>
        </div>
      )}
    </CategoryCard>
  );
}

export default MovingStrategies;