// client/src/components/profileModalTabsElements/PasswordSubTab.js
import React from 'react';

function PasswordSubTab({ profileData, setProfileData }) {
  return (
    <div className="flex justify-center">
      <div className="w-full md:w-1/2 space-y-6">
        {/* Change Password Section */}
        <div>
          <h3 className="sr-only">Change Password</h3>
          <div className="space-y-6">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">Current Password</label>
              <input
                type="password"
                value={profileData.currentPassword}
                onChange={(e) =>
                  setProfileData({ ...profileData, currentPassword: e.target.value })
                }
                className="w-full px-4 py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">New Password</label>
              <input
                type="password"
                value={profileData.newPassword}
                onChange={(e) =>
                  setProfileData({ ...profileData, newPassword: e.target.value })
                }
                className="w-full px-4 py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordSubTab;