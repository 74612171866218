// client/src/pages/Insurance.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * Insurance page. Uses ThreeColumnLayout to display:
 *  - Left column: StepHeader (title="Insurance", stepNumber=6) + SavedRelocationPlans
 *  - Middle column: Budget details for insurance
 *  - Right column: (optional) if you define it
 *
 * Props (passed from App.js):
 *  - relocationPlans (array)
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 */
function Insurance({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch insurance budget info once
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        // e.g. { insurance: { moving, travel, health } }
        setBudget(response.data?.insurance);
      })
      .catch((err) => {
        console.error('Error fetching insurance budget:', err);
      });
  }, []);

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Insurance">
      {budget && (
        <ul className="space-y-2">
          <li>Moving: ${budget.moving}</li>
          <li>Travel: ${budget.travel}</li>
          <li>Health: ${budget.health}</li>
        </ul>
      )}
    </CategoryCard>
  );

  // If you want something in the right column, define rightColumnContent
  // For now, we'll leave it null.

  return (
    <ThreeColumnLayout
      title="Insurance"
      stepNumber={7}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/insurance"
      centerColumn={centerColumnContent}
      // rightColumn={someRightColumnContent}
    />
  );
}

export default Insurance;
