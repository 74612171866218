// client/src/components/NextStepButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

function NextStepButton({
  steps,
  currentStep,
  disabled,
  loading,
  selectedPlanId,
}) {
  const navigate = useNavigate();

  const isLastStep = currentStep >= steps.length - 1;

  const handleClick = () => {
    if (isLastStep) {
      console.log('No more steps!');
      return;
    }
    const nextPath = steps[currentStep + 1].path;
    navigate(nextPath);
  };

  // If you want to show a different icon for the next step:
  const StepIcon =
    !isLastStep && steps[currentStep + 1].icon
      ? steps[currentStep + 1].icon
      : ArrowRightIcon;

  return (
    <div
      className="
        fixed
        z-48
        // Default (mobile): bottom center
        bottom-4 left-1/2 transform -translate-x-1/2
        // Large screens: center right
        lg:top-1/2 lg:right-8 lg:-translate-y-1/2 lg:left-auto
      "
    >
      <button
        onClick={handleClick}
        className="
          group inline-flex items-center justify-center
          rounded-full p-4 border border-gray-300
          bg-white text-green-500 hover:bg-gray-50
          disabled:opacity-50
          mt-4
        "
        disabled={disabled || loading || isLastStep}
        title={isLastStep ? 'No next step' : 'Go to next step'}
      >
        <div className="relative w-8 h-8">
          {/* The StepIcon fades out on hover, ArrowRightIcon fades in */}
          <StepIcon className="absolute inset-0 h-8 w-8 transition-opacity duration-200 opacity-0 group-hover:opacity-100" />
          <ArrowRightIcon className="absolute inset-0 h-8 w-8 transition-opacity duration-200  group-hover:opacity-0" />
        </div>
      </button>
    </div>
  );
}

export default NextStepButton;
