// client/src/components/profileModalTabsElements/ProfileTab.js
import React, { useState } from 'react';
import AccountSubTab from './AccountSubTab';
import PasswordSubTab from './PasswordSubTab';
import SettingsSubTab from './SettingsSubTab';

function ProfileTab({ profileData, setProfileData, handleProfileUpdate }) {
  const [activeSubTab, setActiveSubTab] = useState('Account'); // Subtab for Profile tab
  const profileSubTabs = ['Account', 'Password', 'Settings'];

  return (
    <div>
      {/* Subtabs (Badges) */}
      <div className="flex space-x-2 mb-6">
        {profileSubTabs.map((subTab) => (
          <button
            key={subTab}
            onClick={() => setActiveSubTab(subTab)}
            className={`px-4 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
              activeSubTab === subTab
                ? 'bg-green-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {subTab}
          </button>
        ))}
      </div>

      <form id="profile-form" onSubmit={handleProfileUpdate} className="space-y-8">
        {activeSubTab === 'Account' && (
          <AccountSubTab profileData={profileData} setProfileData={setProfileData} />
        )}

        {activeSubTab === 'Password' && (
          <PasswordSubTab profileData={profileData} setProfileData={setProfileData} />
        )}

        {activeSubTab === 'Settings' && (
          <SettingsSubTab profileData={profileData} setProfileData={setProfileData} />
        )}
      </form>
    </div>
  );
}

export default ProfileTab;