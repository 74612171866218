// client/src/components/StepHeader.js
import React from 'react';

/**
 * Renders a header section with a title and a step indicator.
 * For large devices (lg:), the step number appears in the top-left corner.
 * For smaller devices, it appears in the top-right corner.
 *
 * Props:
 *  - title (string): The main title to display.
 *  - stepNumber (number): The step indicator number (no +1).
 */
function StepHeader({ title, stepNumber }) {
  return (
    <div className="relative py-4 lg:col-span-1">
      <h1 className="pl-2 sm:mt-24 text-3xl sm:text-4xl font-extrabold bg-gradient-to-r from-green-500 via-green-400 to-green-700 bg-clip-text text-transparent">
        {title}
      </h1>

      <span
        className="
        hidden
          absolute
          top-4
          right-4
          lg:left-4
          lg:right-auto
          w-12
          h-12
          border-2
          border-green-400
          text-green-500
          sm:flex
          items-center
          justify-center
          rounded-full
          font-bold
          text-xl
          transition-colors
          duration-200
        "
      >
        {stepNumber}
      </span>
    </div>
  );
}

export default StepHeader;
