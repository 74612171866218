// client/src/pages/RelocationPlans.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../services/api';
import ThreeColumnLayout from '../components/ThreeColumnLayout';
import SavedRelocationPlans from '../components/SavedRelocationPlans';

// Material UI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Headless UI
import { Disclosure } from '@headlessui/react';

// Heroicons
import {
  CheckCircleIcon,
  ChevronDownIcon,
  CheckIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

function RelocationPlans({
  onPlanCreated,
  selectedPlanId: parentSelectedPlanId,
  setSelectedPlanId: setParentSelectedPlanId,
}) {
  // 1) State
  const [relocationPlans, setRelocationPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(parentSelectedPlanId || null);

  // For new plan
  const [newRelocationDate, setNewRelocationDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [newDestinationType, setNewDestinationType] = useState('new destination');
  const [newFamilyMembersCount, setNewFamilyMembersCount] = useState(1);
  const [newCurrencyForPlan, setNewCurrencyForPlan] = useState('');
  const [newCurrentCountry, setNewCurrentCountry] = useState('');
  const [newRelocationCountry, setNewRelocationCountry] = useState('');

  // For editing
  const [editingPlan, setEditingPlan] = useState(null);

  // UI
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // For currency/country
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);

  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlPlanId = queryParams.get('planId');

  // 2) Fetch currency/countries
  useEffect(() => {
    Promise.all([api.get('/currency'), api.get('/countries')])
      .then(([curResp, ctyResp]) => {
        setCurrencies(curResp.data || []);
        setCountries(ctyResp.data || []);
      })
      .catch((err) => console.error('Error fetching currency/countries:', err));
  }, []);

  // 3) Fetch relocation plans
  useEffect(() => {
    fetchRelocationPlans();
  }, []);

  const fetchRelocationPlans = async () => {
    try {
      setLoading(true);
      setError(null);
      const resp = await api.get('/relocation-plans');
      const plans = resp.data || [];
      setRelocationPlans(plans);

      // If none selected, pick first
      if (plans.length > 0 && !selectedPlanId && !urlPlanId && !parentSelectedPlanId) {
        setSelectedPlanId(plans[0].id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(plans[0].id);
      }
    } catch (err) {
      console.error('Error fetching relocation plans:', err);
      setError(err.response?.data?.error || 'Failed to load relocation plans');
    } finally {
      setLoading(false);
    }
  };

  // If we see ?planId=someId => set it active
  useEffect(() => {
    if (!relocationPlans.length) return;
    const newSelectedId =
      urlPlanId && relocationPlans.find((p) => p.id === urlPlanId)
        ? urlPlanId
        : relocationPlans.length > 0 && !selectedPlanId
        ? relocationPlans[0].id
        : selectedPlanId;

    if (newSelectedId !== selectedPlanId) {
      setSelectedPlanId(newSelectedId);
      if (setParentSelectedPlanId) setParentSelectedPlanId(newSelectedId);
    }
  }, [urlPlanId, relocationPlans]);

  // 4) Create plan
  const handleCreatePlan = async () => {
    if (!newRelocationDate || !newDestinationType || newFamilyMembersCount <= 0) {
      setError('Relocation date, destination type, and valid family count required.');
      return;
    }
    try {
      setLoading(true);
      setError(null);

      const body = {
        relocation_date: newRelocationDate,
        destination_type: newDestinationType,
        family_members_count: parseInt(newFamilyMembersCount, 10),
        currency_for_plan: newCurrencyForPlan || undefined,
        current_country: newCurrentCountry || undefined,
        relocation_country: newRelocationCountry || undefined,
      };

      const response = await api.post('/relocation-plans', body);
      if (response.status === 200 && response.data) {
        await fetchRelocationPlans();
        setSelectedPlanId(response.data.id);
        setParentSelectedPlanId && setParentSelectedPlanId(response.data.id);

        // reset
        setNewRelocationDate(new Date().toISOString().split('T')[0]);
        setNewDestinationType('new destination');
        setNewFamilyMembersCount(1);
        setNewCurrencyForPlan('');
        setNewCurrentCountry('');
        setNewRelocationCountry('');

        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);

        onPlanCreated && onPlanCreated();
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error creating plan:', err);
      setError(err.response?.data?.error || 'Failed to create relocation plan');
    } finally {
      setLoading(false);
    }
  };

  // 5) Edit plan
  const handleEditPlan = (plan) => {
    setEditingPlan({
      ...plan,
      currency_for_plan: plan.currency_for_plan || '',
      current_country: plan.current_country || '',
      relocation_country: plan.relocation_country || '',
    });
  };

  const handleSaveEdit = async () => {
    if (!editingPlan || !editingPlan.id) {
      setError('No plan selected for editing.');
      return;
    }
    if (
      !editingPlan.relocation_date ||
      !editingPlan.destination_type ||
      editingPlan.family_members_count <= 0
    ) {
      setError('Date, destination, and valid family count required.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const body = {
        relocation_date: editingPlan.relocation_date,
        destination_type: editingPlan.destination_type,
        family_members_count: parseInt(editingPlan.family_members_count, 10),
        currency_for_plan: editingPlan.currency_for_plan || undefined,
        current_country: editingPlan.current_country || undefined,
        relocation_country: editingPlan.relocation_country || undefined,
      };

      const response = await api.put(`/relocation-plans/${editingPlan.id}`, body);
      if (response.status === 200 && response.data) {
        await fetchRelocationPlans();
        setSelectedPlanId(response.data.id);
        setParentSelectedPlanId && setParentSelectedPlanId(response.data.id);

        setEditingPlan(null);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      } else if (response.status === 404) {
        setError('Plan not found or not authorized');
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error updating plan:', err);
      setError(err.response?.data?.error || 'Failed to update relocation plan');
    } finally {
      setLoading(false);
    }
  };

  // 6) Delete plan
  const handleDeletePlan = async (planId) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.delete(`/relocation-plans/${planId}`);
      if (response.status === 204) {
        await fetchRelocationPlans();
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      } else if (response.status === 404) {
        setError('Relocation plan not found or not authorized');
      }
    } catch (err) {
      console.error('Error deleting plan:', err);
      if (
        err.response?.data?.details &&
        err.response.data.details.includes('violates foreign key constraint')
      ) {
        setError('Cannot delete plan because it includes packs. Remove packs first.');
      } else {
        setError(err.response?.data?.error || 'Failed to delete relocation plan');
      }
    } finally {
      setLoading(false);
    }
  };

  // 7) The center column => form
  const centerColumnContent = (
    <div className="space-y-4">
      {showSuccess && (
        <div className="text-green-600 text-center">
          <p className="inline-flex items-center gap-1 font-medium">
            <CheckCircleIcon className="h-5 w-5" />
            Relocation plan {editingPlan ? 'updated' : 'created'} successfully!
          </p>
        </div>
      )}
      {error && <p className="text-red-500 text-sm">{error}</p>}

      <Disclosure defaultOpen>
        {({ open }) => (
          <div className="border-2 rounded-2xl p-4">
            <Disclosure.Button className="flex w-full justify-between items-center p-4 text-gray-700 hover:text-gray-900">
              <h2 className="text-xl font-bold">
                {editingPlan ? 'Update Plan' : 'New Plan'}
              </h2>
              <ChevronDownIcon
                className={`h-5 w-5 ${open ? 'rotate-180' : ''}`}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="p-4 space-y-4">
              {/* Destination */}
              <TextField
                fullWidth
                label="Destination"
                value={
                  editingPlan ? editingPlan.destination_type : newDestinationType
                }
                onChange={(e) =>
                  editingPlan
                    ? setEditingPlan({
                        ...editingPlan,
                        destination_type: e.target.value,
                      })
                    : setNewDestinationType(e.target.value)
                }
                variant="outlined"
                size="large"
              />

              {/* Date & Persons */}
              <div className="flex gap-2">
                <TextField
                  fullWidth
                  type="date"
                  label="Date"
                  value={
                    editingPlan ? editingPlan.relocation_date : newRelocationDate
                  }
                  onChange={(e) =>
                    editingPlan
                      ? setEditingPlan({
                          ...editingPlan,
                          relocation_date: e.target.value,
                        })
                      : setNewRelocationDate(e.target.value)
                  }
                  variant="outlined"
                  size="large"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Persons"
                  value={
                    editingPlan
                      ? editingPlan.family_members_count
                      : newFamilyMembersCount
                  }
                  onChange={(e) =>
                    editingPlan
                      ? setEditingPlan({
                          ...editingPlan,
                          family_members_count: Math.max(
                            1,
                            parseInt(e.target.value) || 1
                          ),
                        })
                      : setNewFamilyMembersCount(
                          Math.max(1, parseInt(e.target.value) || 1)
                        )
                  }
                  variant="outlined"
                  size="large"
                  inputProps={{ min: 1 }}
                />
              </div>

              {/* Currency */}
              <Autocomplete
                options={currencies}
                getOptionLabel={(option) =>
                  option.code
                    ? `${option.symbol} - ${option.code}  - ${option.title_english}`
                    : ''
                }
                freeSolo
                value={
                  editingPlan
                    ? currencies.find(
                        (c) => c.code === editingPlan.currency_for_plan
                      ) || null
                    : currencies.find((c) => c.code === newCurrencyForPlan) || null
                }
                onChange={(event, newValue) => {
                  const code =
                    newValue?.code || (typeof newValue === 'string' ? newValue : '');
                  if (editingPlan) {
                    setEditingPlan({ ...editingPlan, currency_for_plan: code });
                  } else {
                    setNewCurrencyForPlan(code);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency"
                    variant="outlined"
                    size="large"
                  />
                )}
              />

              {/* Current Country */}
              <Autocomplete
                options={countries}
                getOptionLabel={(option) =>
                  option.iso_alpha2
                    ? `${option.iso_alpha2} - ${option.english_name}`
                    : ''
                }
                freeSolo
                value={
                  editingPlan
                    ? countries.find(
                        (c) => c.iso_alpha2 === editingPlan.current_country
                      ) || null
                    : countries.find((c) => c.iso_alpha2 === newCurrentCountry) ||
                      null
                }
                onChange={(event, newValue) => {
                  const code =
                    newValue?.iso_alpha2 ||
                    (typeof newValue === 'string' ? newValue : '');
                  if (editingPlan) {
                    setEditingPlan({ ...editingPlan, current_country: code });
                  } else {
                    setNewCurrentCountry(code);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Current Country"
                    variant="outlined"
                    size="large"
                  />
                )}
              />

              {/* Relocation Country */}
              <Autocomplete
                options={countries}
                getOptionLabel={(option) =>
                  option.iso_alpha2
                    ? `${option.iso_alpha2} - ${option.english_name}`
                    : ''
                }
                freeSolo
                value={
                  editingPlan
                    ? countries.find(
                        (c) => c.iso_alpha2 === editingPlan.relocation_country
                      ) || null
                    : countries.find((c) => c.iso_alpha2 === newRelocationCountry) ||
                      null
                }
                onChange={(event, newValue) => {
                  const code =
                    newValue?.iso_alpha2 ||
                    (typeof newValue === 'string' ? newValue : '');
                  if (editingPlan) {
                    setEditingPlan({ ...editingPlan, relocation_country: code });
                  } else {
                    setNewRelocationCountry(code);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Relocation Country"
                    variant="outlined"
                    size="large"
                  />
                )}
              />

              {/* Buttons */}
              <div className="grid grid-cols-1 ">
                <button
                  onClick={editingPlan ? handleSaveEdit : handleCreatePlan}
                  className="group relative inline-flex items-center justify-center border-2
                           rounded-full p-2  border-green-500 bg-white text-green-500 
                           hover:bg-green-50 disabled:opacity-50 mt-4"
                  disabled={loading}
                  title={editingPlan ? 'Save changes' : 'Create plan'}
                >
                  {editingPlan ? (
                    <CheckIcon className="h-8 w-8" />
                  ) : (
                    <PlusIcon className="h-8 w-8" />
                  )}
                </button>

                {editingPlan && (
                  <button
                    onClick={() => {
                      setEditingPlan(null);
                      setNewRelocationDate(new Date().toISOString().split('T')[0]);
                      setNewDestinationType('new destination');
                      setNewFamilyMembersCount(1);
                      setNewCurrencyForPlan('');
                      setNewCurrentCountry('');
                      setNewRelocationCountry('');
                    }}
                    className="group relative inline-flex items-center justify-center
                           rounded-full p-2 border-2 border-gray-300 bg-white text-gray-500 
                           hover:bg-gray-50 disabled:opacity-50 mt-4"
                    disabled={loading}
                    title="Cancel edit"
                  >
                    <XMarkIcon className="h-8 w-8" />
                  </button>
                )}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );

  // Right column => All plans
  const rightColumnContent = (
    <SavedRelocationPlans
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={(id) => {
        setSelectedPlanId(id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(id);
      }}
      onEditPlan={handleEditPlan}
      onDeletePlan={handleDeletePlan}
      relocationPlans={relocationPlans} // entire list => labeled "Relocation Plans"
      redirectPath="/relocation-plans"
    />
  );

  return (
    <ThreeColumnLayout
      title="Start Relocation "      // The big heading in the left column
      stepNumber={1}
      // We pass only the active plan => left column auto-labeled "Active Plan"
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={(id) => {
        setSelectedPlanId(id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(id);
      }}
      redirectPath="/relocation-plans"

      centerColumn={centerColumnContent}
      rightColumn={rightColumnContent}
    />
  );
}

export default RelocationPlans;
