// client/src/components/profileModalTabsElements/RelocationPlansTab.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api';
import SavedRelocationPlans from '../SavedRelocationPlans';

function RelocationPlansTab({ relocationPlans, setRelocationPlans, newPlan, setNewPlan, handleAddPlan,   selectedPlanId: parentSelectedPlanId,
    setSelectedPlanId: setParentSelectedPlanId, }) 
   
    {

  // For editing
  const [editingPlan, setEditingPlan] = useState(null);

  // UI
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // For currency/country
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);

  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlPlanId = queryParams.get('planId');

  // 2) Fetch currency/countries
  useEffect(() => {
    Promise.all([api.get('/currency'), api.get('/countries')])
      .then(([curResp, ctyResp]) => {
        setCurrencies(curResp.data || []);
        setCountries(ctyResp.data || []);
      })
      .catch((err) => console.error('Error fetching currency/countries:', err));
  }, []);

  // 3) Fetch relocation plans
  useEffect(() => {
    fetchRelocationPlans();
  }, []);

  const fetchRelocationPlans = async () => {
    try {
      setLoading(true);
      setError(null);
      const resp = await api.get('/relocation-plans');
      const plans = resp.data || [];
      setRelocationPlans(plans);

      // If none selected, pick first
      if (plans.length > 0 && !selectedPlanId && !urlPlanId && !parentSelectedPlanId) {
        setSelectedPlanId(plans[0].id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(plans[0].id);
      }
    } catch (err) {
      console.error('Error fetching relocation plans:', err);
      setError(err.response?.data?.error || 'Failed to load relocation plans');
    } finally {
      setLoading(false);
    }
  };

  // If we see ?planId=someId => set it active
  useEffect(() => {
    if (!relocationPlans.length) return;
    const newSelectedId =
      urlPlanId && relocationPlans.find((p) => p.id === urlPlanId)
        ? urlPlanId
        : relocationPlans.length > 0 && !selectedPlanId
        ? relocationPlans[0].id
        : selectedPlanId;

    if (newSelectedId !== selectedPlanId) {
      setSelectedPlanId(newSelectedId);
      if (setParentSelectedPlanId) setParentSelectedPlanId(newSelectedId);
    }
  }, [urlPlanId, relocationPlans]);



  // 5) Edit plan
  const handleEditPlan = (plan) => {
    setEditingPlan({
      ...plan,
      currency_for_plan: plan.currency_for_plan || '',
      current_country: plan.current_country || '',
      relocation_country: plan.relocation_country || '',
    });
  };

  const handleSaveEdit = async () => {
    if (!editingPlan || !editingPlan.id) {
      setError('No plan selected for editing.');
      return;
    }
    if (
      !editingPlan.relocation_date ||
      !editingPlan.destination_type ||
      editingPlan.family_members_count <= 0
    ) {
      setError('Date, destination, and valid family count required.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const body = {
        relocation_date: editingPlan.relocation_date,
        destination_type: editingPlan.destination_type,
        family_members_count: parseInt(editingPlan.family_members_count, 10),
        currency_for_plan: editingPlan.currency_for_plan || undefined,
        current_country: editingPlan.current_country || undefined,
        relocation_country: editingPlan.relocation_country || undefined,
      };

      const response = await api.put(`/relocation-plans/${editingPlan.id}`, body);
      if (response.status === 200 && response.data) {
        await fetchRelocationPlans();
        setSelectedPlanId(response.data.id);
        setParentSelectedPlanId && setParentSelectedPlanId(response.data.id);

        setEditingPlan(null);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      } else if (response.status === 404) {
        setError('Plan not found or not authorized');
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (err) {
      console.error('Error updating plan:', err);
      setError(err.response?.data?.error || 'Failed to update relocation plan');
    } finally {
      setLoading(false);
    }
  };

  // 6) Delete plan
  const handleDeletePlan = async (planId) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.delete(`/relocation-plans/${planId}`);
      if (response.status === 204) {
        await fetchRelocationPlans();
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
      } else if (response.status === 404) {
        setError('Relocation plan not found or not authorized');
      }
    } catch (err) {
      console.error('Error deleting plan:', err);
      if (
        err.response?.data?.details &&
        err.response.data.details.includes('violates foreign key constraint')
      ) {
        setError('Cannot delete plan because it includes packs. Remove packs first.');
      } else {
        setError(err.response?.data?.error || 'Failed to delete relocation plan');
      }
    } finally {
      setLoading(false);
    }
  };






        const [selectedPlanId, setSelectedPlanId] = useState(parentSelectedPlanId || null);
  return (
    <div className="flex justify-center">
      <div className="w-full md:w-1/2 space-y-6">


<SavedRelocationPlans
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={(id) => {
        setSelectedPlanId(id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(id);
      }}
     // onEditPlan={handleEditPlan}
     // onDeletePlan={handleDeletePlan}
      relocationPlans={relocationPlans} // entire list => labeled "Relocation Plans"
      redirectPath="/relocation-plans"
    />

</div>
</div>

   
  );
}

export default RelocationPlansTab;