import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';

function Timeline() {
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    api.get('/timeline').then(response => setTimeline(response.data));
  }, []);

  return (
    <CategoryCard title="Timeline Planner">
      <ul className="space-y-2">
        {timeline.map((item, index) => (
          <li key={index} className="flex justify-between">
            <span>{item.task}</span>
            <span>{item.date}</span>
          </li>
        ))}
      </ul>
    </CategoryCard>
  );
}

export default Timeline;