// services/api.js
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';

// HARDCODED fallbacks
const HARDCODED_SUPABASE_URL = 'https://rgbmdfaoowqbgshjuwwm.supabase.co';
const HARDCODED_SUPABASE_ANON_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJnYm1kZmFvb3dxYmdzaGp1d3dtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDE3NzkxNTIsImV4cCI6MjA1NzM1NTE1Mn0.sjcyJPSZvgiSbxeFMU9HX19GBHkm4XXP1LC-OxChKI8';
const HARDCODED_API_URL = 'https://move-plan-c9ahgaaabmf2bjew.uksouth-01.azurewebsites.net/api';
const HARDCODED_DOMAIN_CUSTOM = 'https://letspring.com';
const HARDCODED_EMAIL_CONFIRM_REQUIRED = 'false';

// Initialize Supabase client with fallback
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL || HARDCODED_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY || HARDCODED_SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
    },
  }
);

// Initialize axios instance with fallback
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || HARDCODED_API_URL,
  withCredentials: true, // Allow cookies for JWT
});

// Add auth token to requests with logging
api.interceptors.request.use(
  async (config) => {
    const session = await supabase.auth.getSession();
    if (session.data.session?.access_token) {
      config.headers.Authorization = `Bearer ${session.data.session.access_token}`;
      console.log('Request being sent:', config.method.toUpperCase(), config.url);
      console.log('Authorization Header:', config.headers.Authorization);
    } else {
      console.log('No session or token available for request:', config.method.toUpperCase(), config.url);
    }
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Log responses and errors
api.interceptors.response.use(
  (response) => {
    console.log('Response received:', response.status, response.config.url);
    return response;
  },
  (error) => {
    console.error('Response error:', error.response?.status, error.config.url, error.message);
    return Promise.reject(error);
  }
);

export default api;
export { supabase };

// Export domain constants with fallback
export const DOMAIN_CUSTOM = process.env.REACT_APP_DOMAIN_CUSTOM || HARDCODED_DOMAIN_CUSTOM;
export const EMAIL_CONFIRM_REQUIRED =
  process.env.REACT_APP_EMAIL_CONFIRM_REQUIRED || HARDCODED_EMAIL_CONFIRM_REQUIRED;
