import {
    HomeIcon,
    BriefcaseIcon,
    ShoppingCartIcon,
    DocumentTextIcon,
    HomeModernIcon,
    ShieldCheckIcon,
    TruckIcon,
    CurrencyDollarIcon,
  } from '@heroicons/react/24/solid';

  
  // Steps array with icons
  const steps = [
    { label: 'New Relocation Plan', path: '/', icon: HomeIcon },
    { label: 'To-Do', path: '/todo', icon: HomeIcon },
    { label: 'Packing', path: '/packing', icon: BriefcaseIcon },
    { label: 'Sale Items', path: '/sale-items', icon: ShoppingCartIcon },
    { label: 'Pets', path: '/pet-relocation', icon: TruckIcon },
    { label: 'Legal Docs', path: '/legal-docs', icon: DocumentTextIcon },
    { label: 'Insurance', path: '/insurance', icon: ShieldCheckIcon },
    { label: 'Travel', path: '/travel', icon: ShieldCheckIcon },
    { label: 'Setting In', path: '/settling-in', icon: HomeModernIcon },
    { label: 'Total Budget', path: '/budget', icon: CurrencyDollarIcon },
  ];

  export default steps;