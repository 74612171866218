// client/src/components/packingElements/PackingForm.js

import React, { useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ChevronDownIcon, PlusIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';

function PackingForm({
  // Parent states/callbacks
  editingPack,
  loading,
  error,
  showSuccess,

  // states
  itemName,
  setItemName,
  notes,
  setNotes,
  target,
  setTarget,
  quantity,
  setQuantity,
  weight,
  setWeight,
  packWidth,
  setPackWidth,
  packHeight,
  setPackHeight,
  packLength,
  setPackLength,
  packEmptyWeight,
  setPackEmptyWeight,

  selectedPackTypeId,
  setSelectedPackTypeId,
  selectedItemTypeId,
  setSelectedItemTypeId,

  // Updated data
  packTypes,   // e.g. [{id, name, width_cm, height_cm, length_cm, ...}]
  itemTypes,   // e.g. [{id, name, category, ...}]
  targetOptions,
  userEditingName,
  setUserEditingName,

  // callbacks
  handleSubmit,    // Creates new pack
  handleSaveEdit,  // Saves existing pack
  handleCancelEdit, // Cancels editing
}) {
  // 1) Auto-fill dimension fields & notes from pack type
  useEffect(() => {
    if (!selectedPackTypeId) {
      // treat as "custom"
      setPackWidth(0);
      setPackHeight(0);
      setPackLength(0);
      setPackEmptyWeight(0);
      setNotes('');
      return;
    }
    const found = packTypes.find((pt) => pt.id === selectedPackTypeId);
    if (found) {
      setPackWidth(found.width_cm || 0);
      setPackHeight(found.height_cm || 0);
      setPackLength(found.length_cm || 0);
      setPackEmptyWeight(found.empty_weight_kg || 0);
      setNotes(found.notes || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackTypeId]);

  // 2) Auto-generate itemName if user hasn't started editing
  useEffect(() => {
    if (userEditingName) return; // user typed something, do not override

    let name = '';

    // Start with pack type
    if (selectedPackTypeId) {
      const foundPack = packTypes.find((pt) => pt.id === selectedPackTypeId);
      name += foundPack ? foundPack.name : 'CustomPack';
    } else {
      name += 'CustomPack';
    }

    // Dimensions: W-L-H
    name += `-W${packWidth}-L${packLength}-H${packHeight}`;

    // Then item type
    name += '-';
    if (selectedItemTypeId) {
      const foundItem = itemTypes.find((it) => it.id === selectedItemTypeId);
      name += foundItem ? foundItem.name : 'Mixed';
    } else {
      name += 'Mixed';
    }

    setItemName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPackTypeId,
    selectedItemTypeId,
    packWidth,
    packHeight,
    packLength,
    userEditingName,
  ]);

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <div className="border-2  rounded-2xl  sm:p-4">
          <Disclosure.Button className="p-6  flex w-full justify-between items-center sm:p-4 text-gray-700 hover:text-gray-900">
            <h2 className="text-xl font-bold">
              {editingPack ? 'Update Pack' : 'Create Pack'}
            </h2>
            <ChevronDownIcon className={`h-5 w-5 ${open ? 'rotate-180' : ''}`} />
          </Disclosure.Button>

          <Disclosure.Panel className="p-4 space-y-4">
            {/* PACK TYPE AUTOCOMPLETE */}
            <Autocomplete
            
              options={packTypes}
              // Show name plus dimensions in the dropdown
              getOptionLabel={(option) => {
                const w = option.width_cm || 0;
                const h = option.height_cm || 0;
                const l = option.length_cm || 0;
                // Optionally also show category if your packTypes have it
                // e.g. const cat = option.category ? ` - ${option.category}` : '';
                return `${option.name} - ${w}×${h}×${l} cm`;
              }}
              value={packTypes.find((pt) => pt.id === selectedPackTypeId) || null}
              onChange={(event, newValue) => {
                const newId = newValue ? newValue.id : '';
                setSelectedPackTypeId(newId);
                setUserEditingName(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pack Type"
                  variant="outlined"
                  size="large"
              //    helperText="Choose a pack type (with dimensions)"
                />
              )}
            />

            {/* ITEM TYPE AUTOCOMPLETE */}
            <Autocomplete
              options={itemTypes}
              getOptionLabel={(option) => {
                // Show "Name - Category"
                const cat = option.category ? ` |  ${option.category}` : '';
                return `${option.name}${cat}`;
              }}
              value={itemTypes.find((it) => it.id === selectedItemTypeId) || null}
              onChange={(event, newValue) => {
                const newId = newValue ? newValue.id : '';
                setSelectedItemTypeId(newId);
                setUserEditingName(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item Type"
                  variant="outlined"
                  size="large"
                //  helperText="Choose an item type (with category)"
                />
              )}
            />

            {/* NOTES: from pack type or user override */}
            <TextField
              label="Notes"
              value={notes}
              variant="outlined"
              size="small"
              multiline
              rows={2}
              fullWidth
              onChange={(e) => setNotes(e.target.value)}
            //  helperText="Inherited from pack type. Feel free to override."
            />

            {/* DIMENSIONS */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 gap-y-4">
              <TextField
                label="W (cm)"
                type="number"
                value={packWidth}
                onChange={(e) => {
                  setPackWidth(parseInt(e.target.value, 10) || 0);
                  setUserEditingName(false);
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                label="H (cm)"
                type="number"
                value={packHeight}
                onChange={(e) => {
                  setPackHeight(parseInt(e.target.value, 10) || 0);
                  setUserEditingName(false);
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                label="L (cm)"
                type="number"
                value={packLength}
                onChange={(e) => {
                  setPackLength(parseInt(e.target.value, 10) || 0);
                  setUserEditingName(false);
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Pack (kg)"
                type="number"
                value={packEmptyWeight}
                onChange={(e) => {
                  setPackEmptyWeight(parseFloat(e.target.value) || 0);
                  setUserEditingName(false);
                }}
                variant="outlined"
                size="small"
              />
              <TextField
                label="Quantity"
                type="number"
                value={quantity}
                onChange={(e) =>
                  setQuantity(Math.max(1, parseInt(e.target.value) || 1))
                }
                variant="outlined"
                size="small"
              />
            

            {/* TOTAL WEIGHT */}
            <TextField
              label="Total (kg)"
              type="number"
              value={weight}
              onChange={(e) =>
                setWeight(Math.max(0, parseFloat(e.target.value) || 0))
              }
              variant="outlined"
              size="small"
              inputProps={{ step: 0.1, min: 0 }}
              fullWidth
            />
            </div>

            {/* TARGET Radio Buttons */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Target
              </label>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 text-sm">
                {targetOptions.map((opt) => (
                  <label key={opt} className="flex-1">
                    <input
                      type="radio"
                      value={opt}
                      checked={target === opt}
                      onChange={(e) => setTarget(e.target.value)}
                      className="hidden"
                    />
                    <div
                      className={`border rounded-md p-2 text-center cursor-pointer ${
                        target === opt
                          ? 'border-green-500 bg-green-50'
                          : 'border-gray-300'
                      }`}
                    >
                      {opt.charAt(0).toUpperCase() + opt.slice(1)}
                    </div>
                  </label>
                ))}
              </div>
            </div>

            {/* ITEM NAME (auto or custom) */}
            <TextField
              label="Item Name"
              value={itemName}
              variant="outlined"
              size="large"
              fullWidth
              onFocus={() => setUserEditingName(true)}
              onChange={(e) => {
                setItemName(e.target.value);
                setUserEditingName(true);
              }}
              helperText="Auto-generated. You can override if needed."
              className="mt-4"
            />

            {/* SUBMIT / SAVE */}
            
            {!editingPack ? (
                <div className="grid grid-cols-1 ">
              <button
                onClick={handleSubmit}
                className="group relative inline-flex items-center justify-center border-2
                           rounded-full p-2  border-green-500 bg-white text-green-500 
                           hover:bg-green-50 disabled:opacity-50 mt-4"
                disabled={loading}
                title="Add Pack Item"
              >
                <PlusIcon className="h-8 w-8" />
              </button>
              </div>
            ) : (
              <>
              <div className="grid grid-cols-1 ">
                <button
                  onClick={handleSaveEdit}
                  className="group relative inline-flex items-center justify-center border-2
                           rounded-full p-2  border-green-500 bg-white text-green-500 
                           hover:bg-green-50 disabled:opacity-50 mt-4"
                  disabled={loading}
                >
                  <CheckIcon className="h-8 w-8" />
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="group relative inline-flex items-center justify-center border-2
                           rounded-full p-2  border-gray-300 bg-white text-gray-500 
                           hover:bg-green-50 disabled:opacity-50 mt-4"
                  disabled={loading}
                >
                  <XMarkIcon className="h-8 w-8" />
                </button>
                </div>
              </>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}

export default PackingForm;
