// client/src/pages/LegalDocs.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * LegalDocs page. Uses ThreeColumnLayout to display:
 *  - Left column: StepHeader (title="Legal & Docs", stepNumber=5) + SavedRelocationPlans
 *  - Middle column: Budget details for legal docs
 *  - Right column: (optional)
 *
 * Props (passed from App.js):
 *  - relocationPlans (array)
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 */
function LegalDocs({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch budget info once
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        // e.g. { legalDocs: { visas, permits, addressChange } }
        setBudget(response.data?.legalDocs);
      })
      .catch((err) => {
        console.error('Error fetching legal docs budget:', err);
      });
  }, []);

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Legal and Documentation">
      {budget && (
        <ul className="space-y-2">
          <li>Visas: ${budget.visas}</li>
          <li>Permits: ${budget.permits}</li>
          <li>Address Change: ${budget.addressChange}</li>
        </ul>
      )}
    </CategoryCard>
  );

  // If you want something in the right column, define rightColumnContent
  // For now, we'll leave it null.

  return (
    <ThreeColumnLayout
      title="Legal & Docs"
      stepNumber={6}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/legal-docs"
      centerColumn={centerColumnContent}
      // rightColumn={someRightColumnContent}
    />
  );
}

export default LegalDocs;
