// client/src/components/profileModalTabsElements/ToDoTab.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api';

// Components
import SavedRelocationPlans from '../SavedRelocationPlans';
import MyToDoList from '../MyToDoList';

// Example props you might pass to this tab:
// - selectedPlanId, setSelectedPlanId
// - relocationPlans, setRelocationPlans
// - userTodos, setUserTodos (or we can manage them inside this component)

function ToDoTab({
  selectedPlanId: parentSelectedPlanId,
  setSelectedPlanId: setParentSelectedPlanId,
}) {
  // Local state for relocation plans + selected plan
  const [relocationPlans, setRelocationPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(parentSelectedPlanId || null);

  // Local state for user_todo items
  const [userTodos, setUserTodos] = useState([]);
  const [activeTodo, setActiveTodo] = useState(null); // track which to-do is in edit mode (optional)

  // UI
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlPlanId = queryParams.get('planId');

  // 1) Fetch relocation plans once
  useEffect(() => {
    fetchRelocationPlans();
  }, []);

  async function fetchRelocationPlans() {
    try {
      setLoading(true);
      setError(null);

      const resp = await api.get('/relocation-plans');
      const plans = resp.data || [];
      setRelocationPlans(plans);

      // If no plan is selected, pick the first
      if (plans.length > 0 && !selectedPlanId && !urlPlanId && !parentSelectedPlanId) {
        setSelectedPlanId(plans[0].id);
        if (setParentSelectedPlanId) setParentSelectedPlanId(plans[0].id);
      }
    } catch (err) {
      console.error('Error fetching relocation plans:', err);
      setError(err.response?.data?.error || 'Failed to load relocation plans');
    } finally {
      setLoading(false);
    }
  }

  // 2) Handle URL param ?planId=someId => set that as active plan
  useEffect(() => {
    if (!relocationPlans.length) return;

    const newSelectedId =
      urlPlanId && relocationPlans.find((p) => p.id === urlPlanId)
        ? urlPlanId
        : relocationPlans.length > 0 && !selectedPlanId
        ? relocationPlans[0].id
        : selectedPlanId;

    if (newSelectedId !== selectedPlanId) {
      setSelectedPlanId(newSelectedId);
      if (setParentSelectedPlanId) setParentSelectedPlanId(newSelectedId);
    }
  }, [urlPlanId, relocationPlans]);

  // 3) Fetch user_todo items for the selected plan
  useEffect(() => {
    if (selectedPlanId) {
      fetchUserTodos(selectedPlanId);
    } else {
      setUserTodos([]);
    }
  }, [selectedPlanId]);

  async function fetchUserTodos(planId) {
    try {
      setLoading(true);
      setError(null);

      const response = await api.get('/user-todo');
      const allTodos = response.data || [];
      // Filter to only the selected plan
      const planTodos = allTodos.filter((t) => t.relocation_plan_id === planId);
      setUserTodos(planTodos);
    } catch (err) {
      console.error('Error fetching user todos:', err);
      setError('Failed to load to-do items.');
    } finally {
      setLoading(false);
    }
  }

  // 4) Toggling completion (example)
  async function handleToggleComplete(todoItem) {
    try {
      await api.put(`/user-todo/${todoItem.id}`, {
        ...todoItem,
        is_completed: !todoItem.is_completed,
      });
      // Refresh
      fetchUserTodos(selectedPlanId);
    } catch (err) {
      console.error('Error toggling completion:', err);
      setError('Failed to update completion status.');
    }
  }

  // 5) Select item for editing
  function handleSelectTodoForEdit(todoItem) {
    setActiveTodo(todoItem);
    // Possibly open a modal or do something else
  }

  // This is a minimal example. In a real scenario, you might add
  // forms to create/edit a to-do item in the same tab, etc.

  return (
    <div className="p-4">
      {error && <p className="text-red-500">{error}</p>}
      {loading && <p className="text-gray-500">Loading...</p>}


      {/* 2) Display the user's to-do list for the selected plan */}
      <div className="mt-6">
        <MyToDoList
          userTodos={userTodos}
          activeTodo={activeTodo}
          onToggleComplete={handleToggleComplete}
          onSelectTodo={handleSelectTodoForEdit}
        />
      </div>
    </div>
  );
}

export default ToDoTab;
