// client/src/App.js
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,

  Navigate,
} from 'react-router-dom';

import { AuthProvider, useAuth } from './context/AuthContext';
import Navbar from './components/Navbar';
import Navigation from './components/Navigation';
import NextStepButton from './components/NextStepButton';

import ProtectedRoute from './components/ProtectedRoute';

// Page Components
import RelocationPlans from './pages/RelocationPlans';
import ToDo from './pages/ToDoPage';
import Packing from './pages/Packing';
import SaleItems from './pages/SaleItems';
import PetRelocation from './pages/PetRelocation';
import LegalDocs from './pages/LegalDocs';
import Insurance from './pages/Insurance';
import Travel from './pages/Travel';
import SettlingIn from './pages/SettlingIn';
import Budget from './pages/Budget';
import Timeline from './pages/Timeline';
import MovingStrategies from './pages/MovingStrategies';
import Login from './pages/Login';
import Register from './pages/Register';

import steps from './components/Steps';


import api from './services/api';

// Wrapper to handle routing + steps
function AppContent() {
  const { session, handleLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Track current step index (0-based) and plan selection
  const [currentStep, setCurrentStep] = useState(0);

  // Centralized relocation plans + selected plan
  const [relocationPlans, setRelocationPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
    // Store  for global usage
  const [planCurrency, setPlanCurrency] = useState(null);
  const [planCurrentCountry, setPlanCurrentCountry] = useState(null);
  const [planRelocationCountry, setPlanRelocationCountry] = useState(null);



  // 1) Fetch relocation plans once user is logged in
  useEffect(() => {
    if (session) {
      fetchRelocationPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const fetchRelocationPlans = async () => {
    try {
      const response = await api.get('/relocation-plans');
      const plans = response.data || [];
      setRelocationPlans(plans);

      // Optionally auto-select the first plan if none selected
      if (plans.length > 0 && !selectedPlanId) {
        setSelectedPlanId(plans[0].id);
      }
    } catch (error) {
      console.error('Error fetching relocation plans:', error);
    }
  };

  // 2) Update current step based on current path
  useEffect(() => {
    const currentPath = location.pathname;
    const stepIndex = steps.findIndex((step) => step.path === currentPath);
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    }
  }, [location.pathname, steps]);


    // Whenever we pick a plan, fetch it
    useEffect(() => {
      if (selectedPlanId) {
        fetchPlanDetails(selectedPlanId);
      }
    }, [selectedPlanId]);
  
    async function fetchPlanDetails(planId) {
      try {
        const response = await api.get(`/relocation-plans/${planId}`);
        const plan = response.data;
        setPlanCurrency(plan.currency_for_plan || null);
        setPlanCurrentCountry(plan.current_country || null);
        setPlanRelocationCountry(plan.relocation_country || null);
      } catch (err) {
        console.error('Error fetching plan details:', err);
      }
    }

  // Logout resets state
  const handleLogoutWithReset = () => {
    handleLogout();
    setCurrentStep(0);
    setSelectedPlanId(null);
    setRelocationPlans([]);
    navigate('/login');
  };

  return (
    <div className='bg-gradient-to-b from-green-50 to-transparent'>
      {/* Navbar always at top */}
      <Navbar session={session} onLogout={handleLogoutWithReset} />
      <div className='px-4 sm:px-0'>
      {/* Show steps navigation & next button only if user is logged in */}
      {session && (
        <>
        <div className='sm:hidden'>
          <Navigation
            currentStep={currentStep}
            totalSteps={steps.length}
            steps={steps}
          />
          </div>

          <NextStepButton
            steps={steps}
            currentStep={currentStep}
            // Example condition: disable if no selectedPlanId
            disabled={!selectedPlanId}
            loading={false}
            selectedPlanId={selectedPlanId}
          />
        </>
      )}

      <div className="mx-auto ">
        <Routes>
          {/* Step 1 */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <RelocationPlans
                  onPlanCreated={() => navigate('/packing')}
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                  planCurrency={planCurrency}
                  planCurrentCountry={planCurrentCountry}
                  planRelocationCountry={planRelocationCountry}
                />
              </ProtectedRoute>
            }
          />


          {/* Step 2 */}
          <Route
            path="/todo"
            element={
              <ProtectedRoute>
                <ToDo
                  onPackingSaved={() => navigate('/packing')}
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}

                  planCurrency={planCurrency}
                  planCurrentCountry={planCurrentCountry}
                  planRelocationCountry={planRelocationCountry}
                />
              </ProtectedRoute>
            }
          />



          {/* Step 3 */}
          <Route
            path="/packing"
            element={
              <ProtectedRoute>
                <Packing
                  onPackingSaved={() => navigate('/sale-items')}
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}

                  planCurrency={planCurrency}
                  planCurrentCountry={planCurrentCountry}
                  planRelocationCountry={planRelocationCountry}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 4 */}
          <Route
            path="/sale-items"
            element={
              <ProtectedRoute>
                <SaleItems
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 5 */}
          <Route
            path="/pet-relocation"
            element={
              <ProtectedRoute>
                <PetRelocation
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 6 */}
          <Route
            path="/legal-docs"
            element={
              <ProtectedRoute>
                <LegalDocs
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 7 */}
          <Route
            path="/insurance"
            element={
              <ProtectedRoute>
                <Insurance
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 8 */}
          <Route
            path="/travel"
            element={
              <ProtectedRoute>
                <Travel
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 9 */}
          <Route
            path="/settling-in"
            element={
              <ProtectedRoute>
                <SettlingIn
                  relocationPlans={relocationPlans}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Step 10 */}
          <Route
            path="/budget"
            element={
              <ProtectedRoute>
                <Budget 
                                  relocationPlans={relocationPlans}
                                  selectedPlanId={selectedPlanId}
                                  setSelectedPlanId={setSelectedPlanId}
                />
              </ProtectedRoute>
            }
          />

          {/* Additional pages */}
          <Route
            path="/timeline"
            element={
              <ProtectedRoute>
                <Timeline />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moving-strategies"
            element={
              <ProtectedRoute>
                <MovingStrategies />
              </ProtectedRoute>
            }
          />





          {/* Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Fallback: if no route matches, go to step 0 */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      </div>
    </div>
  );
}

// Main App with AuthProvider + Router
function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
