// client/src/pages/ToDoPage.js
import React, { useState, useEffect } from 'react';
import ThreeColumnLayout from '../components/ThreeColumnLayout';
import ToDoForm from '../components/ToDoForm';
import MyToDoList from '../components/MyToDoList';
import api from '../services/api';

function ToDoPage({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  // All user_todo items for the selected plan
  const [userTodos, setUserTodos] = useState([]);
  // The currently active to-do being edited in the center column
  const [activeTodo, setActiveTodo] = useState(null);

  // For showing success/error messages
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedPlanId) {
      fetchUserTodos(selectedPlanId);
    } else {
      setUserTodos([]);
    }
  }, [selectedPlanId]);

  async function fetchUserTodos(planId) {
    try {
      setError(null);
      const response = await api.get('/user-todo');
      const allTodos = response.data || [];
      // Filter by plan
      const planTodos = allTodos.filter((t) => t.relocation_plan_id === planId);
      // Sort by planned_date (ascending), items without a date at the bottom
      planTodos.sort((a, b) => {
        if (!a.planned_date && !b.planned_date) return 0;
        if (!a.planned_date) return 1;
        if (!b.planned_date) return -1;
        return new Date(a.planned_date) - new Date(b.planned_date);
      });
      setUserTodos(planTodos);
    } catch (err) {
      console.error('Error fetching user todos:', err);
      setError('Failed to load your to-do items.');
    }
  }

  // Double-click toggles completion
  async function handleToggleComplete(todoItem) {
    try {
      await api.put(`/user-todo/${todoItem.id}`, {
        ...todoItem,
        is_completed: !todoItem.is_completed,
      });
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      fetchUserTodos(selectedPlanId);
    } catch (err) {
      console.error('Error toggling completion:', err);
      setError('Failed to update completion status.');
    }
  }

  // Single-click sets item for editing in the center column
  function handleSelectTodoForEdit(todoItem) {
    setActiveTodo(todoItem);
  }

  // Refresh after create/update/delete
  function handleRefresh() {
    fetchUserTodos(selectedPlanId);
  }

  // Center column => uses ToDoForm
  const centerColumnContent = (
    <ToDoForm
      activeTodo={activeTodo}
      setActiveTodo={setActiveTodo}
      selectedPlanId={selectedPlanId}
      onRefresh={handleRefresh}
      showSuccess={showSuccess}
      setShowSuccess={setShowSuccess}
      error={error}
      setError={setError}
    />
  );

  // Right column => MyToDoList
  const rightColumnContent = (
    <MyToDoList
      userTodos={userTodos}
      onToggleComplete={handleToggleComplete}
      onSelectTodo={handleSelectTodoForEdit}
    />
  );

  return (
    <ThreeColumnLayout
      title="To-Do"
      stepNumber={2}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/todo"
      centerColumn={centerColumnContent}
      rightColumn={rightColumnContent}
    />
  );
}

export default ToDoPage;
