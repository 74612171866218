// client/src/pages/SaleItems.js
import React, { useState } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

function SaleItems({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [items, setItems] = useState([]);
  const [saleData, setSaleData] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/sale-items', { items });
      setSaleData(response.data);
    } catch (error) {
      console.error('Error submitting sale items:', error);
    }
  };

  const addItem = (e) => {
    if (e.key === 'Enter') {
      const [name, category] = e.target.value.split(',');
      setItems((prev) => [
        ...prev,
        { name: name.trim(), category: (category || 'General').trim() },
      ]);
      e.target.value = '';
    }
  };

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Sale Items">
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          placeholder="Add item (name,category)"
          onKeyPress={addItem}
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button
          type="submit"
          className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Analyze
        </button>
      </form>

      {items.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Items to Sell:</h3>
          <ul className="space-y-2">
            {items.map((item, index) => (
              <li key={index} className="flex justify-between">
                <span>{item.name}</span>
                <span>{item.category}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {saleData && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Sale Analysis:</h3>
          <table className="w-full mt-2 border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-2 border">Name</th>
                <th className="p-2 border">Category</th>
                <th className="p-2 border">Estimated Value</th>
              </tr>
            </thead>
            <tbody>
              {saleData.items.map((item, idx) => (
                <tr key={idx} className="border-b">
                  <td className="p-2">{item.name}</td>
                  <td className="p-2">{item.category}</td>
                  <td className="p-2">${item.saleValue.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </CategoryCard>
  );

  // If you want something in the right column, define rightColumnContent
  // For now, we'll leave it empty (null)

  return (
    <ThreeColumnLayout
      title="Items for Sale"
      stepNumber={4}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/sale-items"
      centerColumn={centerColumnContent}
      // rightColumn={<SomeRightColumnComponent />}
    />
  );
}

export default SaleItems;
