// client/src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Bars3BottomRightIcon, XMarkIcon, UserPlusIcon, ArrowRightEndOnRectangleIcon, PlusIcon, MinusIcon, MapIcon, ArchiveBoxIcon, UserIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import Navigation from './Navigation';
import NextStepButton from './NextStepButton';
import ProfileModal from './ProfileModal'; // Import the new modal
import steps from './Steps';

// Placeholder logo (replace with your actual logo)
import logo from '../images/logo.svg'; // Replace with your logo path

function Navbar({ session, onLogout }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false); // State for Profile modal
  const [selectedTab, setSelectedTab] = useState('Profile'); // State to track the selected tab
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const location = useLocation();

  const handleToggle = () => setIsOpen(!isOpen);

  const isLoggedIn = !!session;

  const handleMainPage = () => navigate('/');
  const handleLogout = () => {
    setIsOpen(false);
    setIsProfileModalOpen(false); // Close modal on logout
    onLogout();
  };
  const handleShowLogin = () => {
    setIsOpen(false);
    navigate('/login');
  };
  const handleShowRegister = () => {
    setIsOpen(false);
    navigate('/register');
  };

  const openProfileModal = (tab) => {
    setSelectedTab(tab); // Set the tab to open in the modal
    setIsProfileModalOpen(true); // Open the modal
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const stepIndex = steps.findIndex((step) => step.path === currentPath);
    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    }
  }, [location.pathname, steps]);

  return (
    <nav className="bg-gradient-to-b from-green-50 to-transparent">
      {/* Top bar with dynamic layout */}
      <div
        className={`px-4 sm:px-6 py-4 ${
          session ? 'grid grid-cols-4 md:grid-cols-8 gap-4' : 'flex justify-between'
        } items-center`}
      >
        {/* Logo (1st column or left-aligned) */}
        <div className={session ? 'col-span-2 flex items-center' : 'flex items-center'}>
          <button
            onClick={handleMainPage}
            className="flex items-center text-gray-900 hover:text-green-600 transition-colors duration-200"
          >
            <img className="h-10 w-auto" src={logo} alt="Let Spring Logo" />
            <span className="ml-2 tracking-tight text-xl sm:text-xl font-extrabold bg-gradient-to-r from-green-400 via-green-500 to-green-700 bg-clip-text text-transparent">
              Let Spring
            </span>
          </button>
        </div>

        {/* Navigation (4 columns, scrollable, hidden on mobile) */}
        {session && (
          <div className="col-span-4 hidden md:block overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <div className="flex items-center space-x-4 min-w-max">
              <Navigation
                currentStep={currentStep}
                totalSteps={steps.length}
                steps={steps}
              />
              <NextStepButton
                steps={steps}
                currentStep={currentStep}
                disabled={!selectedPlanId}
                loading={false}
                selectedPlanId={selectedPlanId}
              />
            </div>
          </div>
        )}

        {/* Right side (1 column or right-aligned: Icons or Hamburger) */}
        <div className={session ? 'col-span-2 flex justify-end items-center' : 'flex justify-end items-center'}>
          {/* Large screen navigation links and icons */}
          <div className="hidden md:flex items-center space-x-6 text-sm">
            {isLoggedIn ? (
              <>
                {/* Budget Menu (Using MapIcon) */}
                <div className="relative group">
                  <button
                    className="flex items-center justify-center p-2 text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
                    title="Budget"
                  >
                    <MapIcon className="h-6 w-6 text-gray-600" />
                  </button>
                  <div className="absolute right-0 mt-0 w-56 bg-white rounded-lg shadow-xl hidden group-hover:block z-50 py-2">
                    <Link to="/budget" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Budget Overview
                    </Link>
                    <Link to="/pet-relocation" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Pet Relocation
                    </Link>
                    <Link to="/legal-docs" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Legal Docs
                    </Link>
                    <Link to="/insurance" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Insurance
                    </Link>
                    <Link to="/settling-in" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Settling In
                    </Link>
                  </div>
                </div>

                {/* Stuff Menu */}
                <div className="relative group">
                  <button
                    className="flex items-center justify-center p-2 text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
                    title="Stuff"
                  >
                    <ArchiveBoxIcon className="h-6 w-6 text-gray-600" />
                  </button>
                  <div className="absolute right-0 mt-0 w-56 bg-white rounded-lg shadow-xl hidden group-hover:block z-50 py-2">
                    <Link to="/packing" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Packing
                    </Link>
                    <Link to="/sale-items" className="block px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200">
                      Sale Items
                    </Link>
                  </div>
                </div>

                {/* Profile Menu (Dropdown with Modal Trigger) */}
                <div className="relative group">
                  <button
                    className="flex items-center justify-center p-2 text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
                    title="Profile"
                  >
                    <UserIcon className="h-6 w-6 text-gray-600" />
                  </button>
                  <div className="absolute right-0 mt-0 w-56 bg-white rounded-lg shadow-xl hidden group-hover:block z-50 py-2">
                    <button
                      onClick={() => openProfileModal('Profile')}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                    >
                      Profile
                    </button>
                    <button
                      onClick={() => openProfileModal('Relocation Plans')}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                    >
                      Relocation Plans
                    </button>
                    <button
                      onClick={() => openProfileModal('Moving Strategy')}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                    >
                      Moving Strategy
                    </button>
                    <button
                      onClick={() => openProfileModal('Timeline')}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                    >
                      Timeline
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <button
                  onClick={handleShowLogin}
                  className="flex items-center justify-center p-2 text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
                  title="Login"
                >
                  <ArrowRightEndOnRectangleIcon className="h-6 w-6 text-gray-600" />
                </button>
                <button
                  onClick={handleShowRegister}
                  className="flex items-center justify-center p-2 text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
                  title="Register"
                >
                  <UserPlusIcon className="h-6 w-6 text-gray-600" />
                </button>
              </>
            )}
          </div>

          {/* Hamburger toggle (visible on mobile) */}
          <button
            onClick={handleToggle}
            className="md:hidden text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 rounded-md p-1 transition-all duration-200"
          >
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3BottomRightIcon className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Hamburger panel on small screens */}
      {isOpen && (
        <div className="md:hidden border-t border-gray-200 bg-white shadow-lg">
          {isLoggedIn ? (
            <>
              {/* Budget Menu */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200">
                      <span className="text-base font-medium text-gray-700">Budget</span>
                      {open ? <MinusIcon className="h-5 w-5" /> : <PlusIcon className="h-5 w-5" />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-8">
                      <Link
                        to="/budget"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Budget Overview
                      </Link>
                      <Link
                        to="/pet-relocation"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Pet Relocation
                      </Link>
                      <Link
                        to="/legal-docs"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Legal Docs
                      </Link>
                      <Link
                        to="/insurance"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Insurance
                      </Link>
                      <Link
                        to="/settling-in"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Settling In
                      </Link>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* Stuff Menu */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200">
                      <span className="text-base font-medium text-gray-700">Stuff</span>
                      {open ? <MinusIcon className="h-5 w-5" /> : <PlusIcon className="h-5 w-5" />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-8">
                      <Link
                        to="/packing"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Packing
                      </Link>
                      <Link
                        to="/sale-items"
                        onClick={() => setIsOpen(false)}
                        className="block px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Sale Items
                      </Link>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* Profile Menu (Disclosure with Modal Trigger) */}
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200">
                      <span className="text-base font-medium text-gray-700">Profile</span>
                      {open ? <MinusIcon className="h-5 w-5" /> : <PlusIcon className="h-5 w-5" />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-8">
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          openProfileModal('Profile');
                        }}
                        className="block w-full text-left px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Profile
                      </button>
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          openProfileModal('Relocation Plans');
                        }}
                        className="block w-full text-left px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Relocation Plans
                      </button>
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          openProfileModal('Moving Strategy');
                        }}
                        className="block w-full text-left px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Moving Strategy
                      </button>
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          openProfileModal('Timeline');
                        }}
                        className="block w-full text-left px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Timeline
                      </button>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-6 py-2 text-gray-700 hover:bg-green-50 rounded-md text-sm font-medium transition-colors duration-200"
                      >
                        Logout
                      </button>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </>
          ) : (
            <>
              <button
                onClick={handleShowLogin}
                className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
              >
                <span className="text-base font-medium text-gray-700">Login</span>
                <ArrowRightEndOnRectangleIcon className="h-5 w-5 text-gray-600" />
              </button>
              <button
                onClick={handleShowRegister}
                className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
              >
                <span className="text-base font-medium text-gray-700">Register</span>
                <UserPlusIcon className="h-5 w-5 text-gray-600" />
              </button>
            </>
          )}
        </div>
      )}

      {/* Profile Modal */}
      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
        onLogout={handleLogout}
        session={session}
        initialTab={selectedTab} // Pass the selected tab to the modal
      />
    </nav>
  );
}

export default Navbar;