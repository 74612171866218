// client/src/pages/SettlingIn.js
import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CategoryCard from '../components/CategoryCard';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

/**
 * SettlingIn page. Uses ThreeColumnLayout to display:
 *  - Left column: StepHeader (title="Settling In", stepNumber=8) + SavedRelocationPlans
 *  - Middle column: Budget details for settling in
 *  - Right column: (optional) if needed
 *
 * Props:
 *  - relocationPlans (array)
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 */
function SettlingIn({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  const [budget, setBudget] = useState(null);

  // Fetch budget info for settling in
  useEffect(() => {
    api.get('/budget')
      .then((response) => {
        // e.g. { settlingIn: { groceries, furniture, internet } }
        setBudget(response.data?.settlingIn);
      })
      .catch((err) => {
        console.error('Error fetching settling-in budget:', err);
      });
  }, []);

  // Build the center column content
  const centerColumnContent = (
    <CategoryCard title="Settling In">
      {budget && (
        <ul className="space-y-2">
          <li>Groceries: ${budget.groceries}</li>
          <li>Furniture: ${budget.furniture}</li>
          <li>Internet: ${budget.internet}</li>
        </ul>
      )}
    </CategoryCard>
  );

  // If you want something in the right column, define rightColumnContent
  // For now, we'll leave it null.

  return (
    <ThreeColumnLayout
      title="Settling In"
      stepNumber={9}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/settling-in"
      centerColumn={centerColumnContent}
      // rightColumn={someRightContent}
    />
  );
}

export default SettlingIn;
