// client/src/components/SavedRelocationPlans.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';

function SavedRelocationPlans({
  selectedPlanId,
  setSelectedPlanId,
  onEditPlan,
  onDeletePlan,
  relocationPlans,
  redirectPath = '/',
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('SavedRelocationPlans => relocationPlans:', relocationPlans);
  }, [relocationPlans]);

  // Clicking a plan row => immediately set it active
  const handlePlanSelect = (plan) => {
    setSelectedPlanId(plan.id);
    // Update ?planId=... in the URL if desired
    if (location.pathname !== redirectPath) {
      navigate(
        `${location.pathname}${location.pathname.includes('?') ? '&' : '?'}planId=${plan.id}`
      );
    }
  };

  // If there's only 1 plan, label the panel "Active Plan", else "Relocation Plans"
  const panelTitle =
    relocationPlans.length === 1 ? 'Active Plan' : 'Relocation Plans';

  return (
    <div className="bg-gray-50 p-4 border-2 rounded-lg rounded-2xl">
      {relocationPlans.length > 0 ? (
        <Disclosure defaultOpen>
          {({ open }) => (
            <div>
              <Disclosure.Button className="flex w-full justify-between items-center text-left text-gray-700 hover:text-gray-900 p-2">
                <span className="text-xl font-bold">{panelTitle}</span>
                <ChevronDownIcon
                  className={`h-5 w-5 transform ${open ? 'rotate-180' : ''}`}
                />
              </Disclosure.Button>

              <Disclosure.Panel className="mt-2">
                <div className="grid grid-cols-1 gap-2">
                  {relocationPlans.map((plan) => (
                    <div
                      key={plan.id}
                      className={`m-2 relative p-4 border rounded-md transition-colors ${
                        selectedPlanId === plan.id
                          ? 'bg-green-50 border-green-300'
                          : 'bg-white border-gray-200'
                      } hover:shadow-sm flex justify-between items-center`}
                    >
                      {/* Entire row is clickable => immediate activation */}
                      <button
                        onClick={() => handlePlanSelect(plan)}
                        className="flex-1 text-left"
                      >
                        {/* Destination + date + family members */}
                        <p className="text-sm font-semibold text-gray-700">
                          {plan.destination_type}
                        </p>
                        <div className="flex gap-2 mt-1">
                          <span className="inline-block text-xs text-green-700 bg-green-50 border border-green-300 px-1 py-0.5 rounded-full">
                            {plan.family_members_count} persons
                          </span>
                          <span className="inline-block text-xs text-purple-700 bg-purple-50 border border-purple-300 px-1 py-0.5 rounded-full">
                            {plan.relocation_date}
                          </span>
                        </div>

                        {/* Additional row: currency + currentCountry + relocationCountry */}
                        <div className="flex gap-2 justify-end items-center mt-2">
                          {/* 1) Currency symbol + tooltip */}
                          {plan.currency?.symbol && (
                            <div className="relative group inline-block">
                              <span className="inline-block text-xs text-blue-600  px-1 py-0.5 rounded-full">
                                {plan.currency.symbol}
                              </span>
                              {/* Tooltip */}
                              <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-sm top-full left-1/2 transform -translate-x-1/2 mt-1 z-50 whitespace-nowrap">
                              <p className="font-medium uppercase">Currency for plan</p>
                                <p>Code: {plan.currency.code}</p>
                                <p>English: {plan.currency.title_english}</p>
                                <p>Native: {plan.currency.title_native}</p>
                              </div>
                            </div>
                          )}

                          {/* 2) Current Country => emoji_flag + tooltip */}
                          {plan.currentCountry?.emoji_flag && (
                            <div className="relative group inline-block">
                                
                              <span className="inline-block text-xs text-blue-600 bg-blue-50 border border-blue-300 px-1 py-0.5 rounded-full">
                                {plan.currentCountry.emoji_flag}
                              </span>
                              {/* Tooltip */}
                              <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-sm top-full left-1/2 transform -translate-x-1/2 mt-1 z-50 whitespace-nowrap">
                              <p className="font-medium uppercase">Current Country</p>
                                <p>iso_alpha2: {plan.currentCountry.iso_alpha2}</p>
                                <p>iso_alpha3: {plan.currentCountry.iso_alpha3}</p>
                                <p>numeric_code: {plan.currentCountry.numeric_code}</p>
                                <p>english_name: {plan.currentCountry.english_name}</p>
                                <p>native_name: {plan.currentCountry.native_name}</p>
                                <p>region: {plan.currentCountry.region}</p>
                                <p>subregion: {plan.currentCountry.subregion}</p>
                                <p>phone_code: {plan.currentCountry.phone_code}</p>
                              </div>
                            </div> 
                          )}
                        <span className='text-xs text-green-500'>→</span>
                          {/* 3) Relocation Country => emoji_flag + tooltip */}
                          {plan.relocationCountry?.emoji_flag && (
                            <div className="relative group inline-block">
                              <span className="inline-block text-xs text-blue-600 bg-blue-50 border border-blue-300 px-1 py-0.5 rounded-full">
                                {plan.relocationCountry.emoji_flag}
                              </span>
                              {/* Tooltip */}
                              <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-sm top-full left-1/2 transform -translate-x-1/2 mt-1 z-50 whitespace-nowrap">
                              <p className="font-medium uppercase">relocation Country</p>
                                <p>iso_alpha2: {plan.relocationCountry.iso_alpha2}</p>
                                <p>iso_alpha3: {plan.relocationCountry.iso_alpha3}</p>
                                <p>numeric_code: {plan.relocationCountry.numeric_code}</p>
                                <p>english_name: {plan.relocationCountry.english_name}</p>
                                <p>native_name: {plan.relocationCountry.native_name}</p>
                                <p>region: {plan.relocationCountry.region}</p>
                                <p>subregion: {plan.relocationCountry.subregion}</p>
                                <p>phone_code: {plan.relocationCountry.phone_code}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </button>

                      {/* Edit/Delete icons if needed */}
                      <div className="absolute top-1 right-1 space-x-1">
                        {onEditPlan && (
                          <button
                            onClick={() => {
                              setSelectedPlanId(plan.id);
                              onEditPlan(plan);
                            }}
                            className="text-gray-400 hover:text-gray-600"
                            title="Edit"
                          >
                            <PencilIcon className="h-3 w-3" />
                          </button>
                        )}
                        {onDeletePlan && (
                          <button
                            onClick={() => onDeletePlan(plan.id)}
                            className="text-gray-400 hover:text-gray-600"
                            title="Delete"
                          >
                            <XMarkIcon className="h-3 w-3" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      ) : (
        <p className="text-gray-600">No saved plans yet.</p>
      )}
    </div>
  );
}

export default SavedRelocationPlans;
