// client/src/components/ProfileModal.js
import React, { useState, useEffect } from 'react';
import { UserIcon, MapIcon, TruckIcon, CalendarIcon } from '@heroicons/react/24/outline';
import ProfileTab from './profileModalTabsElements/ProfileTab';
import RelocationPlansTab from './profileModalTabsElements/RelocationPlansTab';
import MovingStrategyTab from './profileModalTabsElements/MovingStrategyTab';
import ToDoTab from './profileModalTabsElements/ToDoTab';

function ProfileModal({ isOpen, onClose, session, initialTab }) {
  const [activeTab, setActiveTab] = useState(initialTab || 'Profile'); // Main tab
  const [profileData, setProfileData] = useState({
    username: session?.user?.user_metadata?.username || 'User',
    email: session?.user?.email || 'user@example.com',
    currentPassword: '',
    newPassword: '',
    firstName: session?.user?.user_metadata?.first_name || '',
    lastName: session?.user?.user_metadata?.last_name || '',
    currentCountry: session?.user?.user_metadata?.current_country || '',
    preferredCurrency: session?.user?.user_metadata?.preferred_currency || '',
    persons: session?.user?.user_metadata?.persons || '',
  });
  const [relocationPlans, setRelocationPlans] = useState([
    { id: 1, destination: 'New York', date: '2025-06-01' },
    { id: 2, destination: 'London', date: '2025-09-15' },
  ]);
  const [newPlan, setNewPlan] = useState({ destination: '', date: '' });
  const [movingStrategy, setMovingStrategy] = useState([
    { id: 1, task: 'Hire movers', completed: false },
    { id: 2, task: 'Pack items', completed: true },
    { id: 3, task: 'Book flights', completed: false },
  ]);
  const [toDoEvents, setToDoEvents] = useState([
    { id: 1, event: 'Sign lease', date: '2025-05-01' },
    { id: 2, event: 'Move to new city', date: '2025-06-01' },
  ]);
  const [newEvent, setNewEvent] = useState({ event: '', date: '' });

  // Update activeTab when initialTab changes (e.g., when modal reopens with a new tab)
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  if (!isOpen) return null;

  const tabs = [
    { name: 'Profile', icon: UserIcon },
    { name: 'Relocation Plans', icon: MapIcon },
    { name: 'To-Do', icon: CalendarIcon },
    { name: 'Moving Strategy', icon: TruckIcon },
   
  ];

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    // Placeholder for updating profile (e.g., API call to Supabase)
    console.log('Profile updated:', profileData);
  };

  const handleAddPlan = (e) => {
    e.preventDefault();
    if (newPlan.destination && newPlan.date) {
      setRelocationPlans([
        ...relocationPlans,
        { id: relocationPlans.length + 1, ...newPlan },
      ]);
      setNewPlan({ destination: '', date: '' });
    }
  };

  const handleToggleTask = (id) => {
    setMovingStrategy(
      movingStrategy.map((task) =>
        task.id === id ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const handleAddEvent = (e) => {
    e.preventDefault();
    if (newEvent.event && newEvent.date) {
      setToDoEvents([
        ...toDoEvents,
        { id: toDoEvents.length + 1, ...newEvent },
      ]);
      setNewEvent({ event: '', date: '' });
    }
  };

  const ActiveTabIcon = tabs.find((tab) => tab.name === activeTab)?.icon;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-br from-white to-green-50 lg:rounded-lg p-8 w-full h-full lg:w-1/2 sm:h-[75%]  flex flex-col">
        {/* Screen-Reader-Only Title */}
        <h2 className="sr-only">{activeTab}</h2>

        {/* Header: Icon, Tabs, and Close Button */}
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            {ActiveTabIcon && (
              <ActiveTabIcon className="hidden sm:block h-6 w-6 text-green-600" />
            )}
            <div className="flex space-x-4">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setActiveTab(tab.name)}
                  className={`relative px-3 py-1 font-medium transition-all duration-300 ${
                    activeTab === tab.name
                      ? 'text-green-600 text-lg lg:text-xl'
                      : 'text-gray-600 hover:text-green-600'
                  }`}
                  title={tab.name} // Tooltip for all devices
                >
                  <span className="lg:inline hidden">{tab.name}</span>
                  <tab.icon
                    className={`h-6 w-6 inline lg:hidden ${
                      activeTab === tab.name ? 'text-green-600' : 'text-gray-600'
                    }`}
                  />
                  {activeTab === tab.name && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-green-500 transform scale-x-100 transition-transform duration-300 origin-left"></span>
                  )}
                  {activeTab !== tab.name && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-green-500 transform scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                  )}
                </button>
              ))}
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Tab Content (Scrollable) */}
        <div className="flex-1 overflow-y-auto text-gray-700">
          {activeTab === 'Profile' && (
            <ProfileTab
              profileData={profileData}
              setProfileData={setProfileData}
              handleProfileUpdate={handleProfileUpdate}
            />
          )}

          {activeTab === 'Relocation Plans' && (
            <RelocationPlansTab
              relocationPlans={relocationPlans}
              setRelocationPlans={setRelocationPlans}
              newPlan={newPlan}
              setNewPlan={setNewPlan}
              handleAddPlan={handleAddPlan}
            />
          )}

          {activeTab === 'Moving Strategy' && (
            <MovingStrategyTab
              movingStrategy={movingStrategy}
              setMovingStrategy={setMovingStrategy}
              handleToggleTask={handleToggleTask}
            />
          )}

          {activeTab === 'To-Do' && (
            <ToDoTab
              toDoEvents={toDoEvents}
              setToDoEvents={setToDoEvents}
              newEvent={newEvent}
              setNewEvent={setNewEvent}
              handleAddEvent={handleAddEvent}
            />
          )}
        </div>

        {/* Button Aligned to Bottom (Profile Tab Only) */}
        {activeTab === 'Profile' && (
          <div className="mt-4 flex justify-center sticky bottom-0 bg-gradient-to-br from-white to-green-50 py-4">
            <button
              type="submit"
              form="profile-form"
              className="text-xl w-1/2 px-4 py-4 bg-green-600 text-white rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 font-bold"
            >
              Update Profile
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileModal;