// client/src/components/profileModalTabsElements/SettingsSubTab.js
import React from 'react';

function SettingsSubTab({ profileData, setProfileData }) {
  return (
    <div className="flex justify-center">
      <div className="w-full md:w-1/2 space-y-6">
        {/* Settings Section */}
        <div>
          <h3 className="sr-only">Settings</h3>
          <div className="space-y-6">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">Current Country (optional)</label>
              <input
                type="text"
                value={profileData.currentCountry}
                onChange={(e) =>
                  setProfileData({ ...profileData, currentCountry: e.target.value })
                }
                className="w-full px-4 py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">Preferred Currency (optional)</label>
              <input
                type="text"
                value={profileData.preferredCurrency}
                onChange={(e) =>
                  setProfileData({ ...profileData, preferredCurrency: e.target.value })
                }
                className="w-full px-4 py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-1">Persons (number)</label>
              <input
                type="number"
                value={profileData.persons}
                onChange={(e) =>
                  setProfileData({ ...profileData, persons: e.target.value })
                }
                className="w-full px-4 py-4 border-2 border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsSubTab;