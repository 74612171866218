// client/src/components/packingElements/SavedPackingList.js
import React from 'react';
import { Disclosure } from '@headlessui/react';
import {
  PencilIcon,
  XMarkIcon,
  TruckIcon,
  BriefcaseIcon,
  ShoppingCartIcon,
  ArchiveBoxIcon,
  GiftIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';

/**
 * Helper: return an icon for each target
 */
function getTargetIcon(target) {
  switch (target) {
    case 'relocate':
      return <TruckIcon className="h-4 w-4" />;
    case 'luggage':
      return <BriefcaseIcon className="h-4 w-4" />;
    case 'sale':
      return <ShoppingCartIcon className="h-4 w-4" />;
    case 'storage':
      return <ArchiveBoxIcon className="h-4 w-4" />;
    case 'gift':
      return <GiftIcon className="h-4 w-4" />;
    case 'recycle':
      return <ArrowPathIcon className="h-4 w-4 " />;
    default:
      return null;
  }
}

/**
 * SavedPackingList
 *
 * Props:
 *  - savedPlans (object)
 *  - selectedDate (string or null)
 *  - targetOptions (array) => e.g. ['relocate','luggage','sale','storage','gift','recycle']
 *  - handleEditPack (fn)
 *  - handleDeletePack (fn)
 *  - onReTargetPack (fn) => called after dropping a pack into a new target
 */
function SavedPackingList({
  savedPlans,
  selectedDate,
  targetOptions,
  handleEditPack,
  handleDeletePack,
  onReTargetPack,
}) {
  if (!selectedDate || !savedPlans[selectedDate]) {
    return null; // No data for this date
  }

  // Drag & drop handlers
  const handleDragStart = (e, packId) => {
    e.dataTransfer.setData('packId', packId);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const packId = e.dataTransfer.getData('packId');
    const newTarget = e.currentTarget.dataset.target;
    if (onReTargetPack && packId && newTarget) {
      onReTargetPack(packId, newTarget);
    }
  };

  return (
    <div className="rounded-md">
      {targetOptions.map((tg) => {
        const packsForTarget = savedPlans[selectedDate].plans.filter(
          (p) => p.target === tg
        );

        // We'll wrap each target zone in a Disclosure.
        // If you prefer them open by default, use defaultOpen
        return (
          <Disclosure
            key={tg}
           // defaultOpen={packsForTarget.length > 0} // open if items exist
          >
            {({ open }) => (
              <div
                data-target={tg}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className="mb-8 border-2 border-gray-300  rounded-2xl bg-gray-50 p-3"
              >
                {/* The button for the Disclosure: shows target icon + name */}
                <Disclosure.Button className="flex w-full justify-between items-center p-4 text-gray-700 hover:text-gray-900">
                 <div className='flex items-center space-x-4'>
                     <span className="text-gray-400"> {getTargetIcon(tg)}</span>
                    <span className="capitalize text-xl font-semibold">  {tg}</span>
                    
                 </div>
                  <span className=" text-gray-400 text-3xl">
                    {open ? '-' : '+'}
                  </span>
                </Disclosure.Button>

                {/* The collapsible panel */}
                <Disclosure.Panel>
                  {packsForTarget.length === 0 ? (
                    // If no items, show "No items"
                    <p className="mt-4 text-xs text-gray-400 italic">No items</p>
                  ) : (
                    <div>
                    
                    <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
                         
                      {packsForTarget.map((pack) => {
                        const widthVal = pack.custom_width_cm || 0;
                        const heightVal = pack.custom_height_cm || 0;
                        const lengthVal = pack.custom_length_cm || 0;

                        return (
                          <div
                            key={pack.id}
                            className="
                              relative
                              overflow-visible
                              bg-gray-100
                              border border-gray-200
                              rounded-lg
                              p-4 pt-2
                              w-full
                              flex flex-col
                              justify-between
                            "
                            draggable
                            onDragStart={(e) => handleDragStart(e, pack.id)}
                          >
                            {/* Edit/Delete icons */}
                            <div className="absolute top-2 right-2 flex space-x-1">
                              <button
                                onClick={() => handleEditPack(pack)}
                                className="text-gray-400 hover:text-gray-600"
                                title="Edit"
                              >
                                <PencilIcon className="h-3 w-3" />
                              </button>
                              <button
                                onClick={() => handleDeletePack(pack)}
                                className="text-gray-400 hover:text-gray-600"
                                title="Delete"
                              >
                                <XMarkIcon className="h-3 w-3" />
                              </button>
                            </div>

                            {/* Main content */}
                            
                              <p className="text-xs text-gray-600">
                                {widthVal}×{lengthVal}×{heightVal} cm
                                
                              </p>
                              <div className="flex-1 my-2 mb-4">
                              <h4 className="text-xs font-semibold text-gray-800 ">
                                {pack.name || 'Unnamed Pack'}
                              </h4>
                            </div>

                            <div className="absolute bottom-2 left-2 group">
                            <p className="text-xs text-gray-600">{pack.weight} kg</p>
                                </div>
                            {/* "i" tooltip at bottom-right */}
                            <div className="absolute bottom-2 right-2 group">
                            
                              <button
                                className="rounded-full w-4 h-4
                                           border border-green-600 text-green-600 text-[10px]
                                           flex items-center justify-center
                                           hover:bg-green-50 focus:outline-none"
                                title="Show notes"
                              >
                                i
                              </button>
                              {/* Tooltip on hover */}
                              <div
                                className="hidden group-hover:block
                                           absolute bottom-full right-0 mb-1 w-44
                                           bg-black text-white text-xs rounded p-2
                                           shadow-lg z-50"
                              >
                                {pack.notes?.trim() ? pack.notes : 'No notes'}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    </div>
                  )}
                </Disclosure.Panel>
              </div>
              
            )}
          </Disclosure>
        );
      })}
    </div>
  );
}

export default SavedPackingList;
