// client/src/components/ToDoForm.js
import React, { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import {
  PlusIcon,
  CheckIcon,
  XMarkIcon,
  TrashIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';

import TextField from '@mui/material/TextField';
import api from '../services/api';

function ToDoForm({
  activeTodo,
  setActiveTodo,
  selectedPlanId,
  onRefresh,
  showSuccess,
  setShowSuccess,
  error,
  setError
}) {
  const [defaultTodos, setDefaultTodos] = useState([]);

  // Default planned_date => today
  const [formData, setFormData] = useState({
    id: null,
    todo_id: null,           // references the default to-do if chosen
    custom_action: '',
    description: '',
    planned_date: new Date().toISOString().split('T')[0], // today's date
  });

  useEffect(() => {
    fetchDefaultTodos();
  }, []);

  // Load or reset form when activeTodo changes
  useEffect(() => {
    if (activeTodo) {
      setFormData({
        id: activeTodo.id,
        todo_id: activeTodo.todo_id || null,
        custom_action: activeTodo.custom_action || '',
        description: activeTodo.description || '',
        planned_date: activeTodo.planned_date || new Date().toISOString().split('T')[0],
      });
    } else {
      // New item => reset with today's date
      setFormData({
        id: null,
        todo_id: null,
        custom_action: '',
        description: '',
        planned_date: new Date().toISOString().split('T')[0],
      });
    }
  }, [activeTodo]);

  async function fetchDefaultTodos() {
    try {
      const resp = await api.get('/todo');
      setDefaultTodos(resp.data || []);
    } catch (err) {
      console.error('Error fetching default todos:', err);
    }
  }

  // When user clicks a badge => store todo_id + copy its action/description
  function handleBadgeClick(td) {
    setFormData((prev) => ({
      ...prev,
      todo_id: td.id,
      custom_action: td.todo_action,
      description: td.description || '',
    }));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!selectedPlanId) {
      return alert('No relocation plan selected.');
    }
    if (!formData.custom_action) {
      return alert('Please enter an action or select a badge.');
    }

    try {
      setError(null);

      const payload = {
        relocation_plan_id: selectedPlanId,
        todo_id: formData.todo_id, // if user picked a badge
        custom_action: formData.custom_action,
        description: formData.description,
        planned_date: formData.planned_date,
      };

      if (!formData.id) {
        // Create new user_todo
        await api.post('/user-todo', payload);
      } else {
        // Update existing user_todo
        await api.put(`/user-todo/${formData.id}`, payload);
      }

      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);

      onRefresh();
      setActiveTodo(null);
    } catch (err) {
      console.error('Error saving user_todo:', err);
      setError('Failed to save your to-do item.');
    }
  }

  function handleCancelEdit() {
    setActiveTodo(null);
  }

  async function handleDelete() {
    if (!formData.id) return;
    if (!window.confirm('Are you sure you want to delete this action?')) return;

    try {
      await api.delete(`/user-todo/${formData.id}`);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);

      onRefresh();
      setActiveTodo(null);
    } catch (err) {
      console.error('Error deleting user_todo:', err);
      setError('Failed to delete your to-do item.');
    }
  }

  const isEditing = !!formData.id;

  return (
    <div className="space-y-4">
      {showSuccess && !error && (
        <div className="text-green-600 text-center">
          <p className="inline-flex items-center gap-1 font-medium">
            <CheckIcon className="h-5 w-5" />
            {isEditing ? 'Action updated successfully!' : 'Action created successfully!'}
          </p>
        </div>
      )}
      {error && <p className="text-red-500 text-sm text-center">{error}</p>}

      <Disclosure defaultOpen>
        {({ open }) => (
          <div className="border-2 rounded-2xl p-4">
            <Disclosure.Button className="flex w-full justify-between items-center p-4 text-gray-700 hover:text-gray-900">
              <h2 className="text-xl font-bold">
                {isEditing ? 'Edit Action' : 'New Action'}
              </h2>
              <ChevronDownIcon
                className={`h-5 w-5 transition-transform ${open ? 'rotate-180' : ''}`}
              />
            </Disclosure.Button>

            <Disclosure.Panel className="p-4 space-y-4">
              {/* BADGES (Monochrome Emoji), scrollable up to ~4 rows */}
              <div className="mb-4 flex flex-wrap overflow-y-auto max-h-48">
                {defaultTodos.map((td) => (
                  <span
                    key={td.id}
                    onClick={() => handleBadgeClick(td)}
                    className="cursor-pointer bg-gray-200 hover:bg-gray-300 text-xs px-2 py-1 m-1 rounded inline-flex items-center"
                    style={{ filter: 'grayscale(100%)' }} // Monochrome
                  >
                    {/* {td.emoji} */}  {td.todo_action}
                  </span>
                ))}
              </div>

              {/* ACTION NAME (custom_action) */}
              <TextField
                fullWidth
                label="Action"
                name="custom_action"
                variant="outlined"
                size="large"
                value={formData.custom_action}
                onChange={handleChange}
              />

              {/* DESCRIPTION */}
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                name="description"
                variant="outlined"
                size="large"
                value={formData.description}
                onChange={handleChange}
              />

              {/* PLANNED DATE */}
              <TextField
                fullWidth
                type="date"
                label="Planned Date"
                name="planned_date"
                variant="outlined"
                size="large"
                InputLabelProps={{ shrink: true }}
                value={formData.planned_date}
                onChange={handleChange}
              />

              {/* ACTION BUTTONS */}
              <div className="grid grid-cols-1 gap-4 mt-4">
                {/* Create or Update */}
                <button
                  onClick={handleSubmit}
                  className="group relative inline-flex items-center justify-center 
                             border-2 rounded-full p-2 border-green-500 bg-white text-green-500
                             hover:bg-green-50 disabled:opacity-50"
                  title={isEditing ? 'Save changes' : 'Create action'}
                >
                  {isEditing ? (
                    <CheckIcon className="h-8 w-8" />
                  ) : (
                    <PlusIcon className="h-8 w-8" />
                  )}
                </button>

                {/* If editing, show delete + cancel each on full-width row */}
                {isEditing && (

                    
                  <div className='flex justify-between'>

                    <button
                      onClick={handleDelete}
                      className="inline-flex items-center justify-center
                                 rounded-full p-2 border-2 border-red-500 bg-white text-red-500 
                                 hover:bg-red-50 disabled:opacity-50"
                      title="Delete this action"
                    >
                      <TrashIcon className="h-8 w-8" />
                      <span className="ml-2 font-semibold sr-only">Delete</span>
                    </button>
                <button
                      onClick={handleCancelEdit}
                      className="inline-flex items-center justify-center
                                 rounded-full p-2 border-2 border-gray-300 bg-white text-gray-500 
                                 hover:bg-gray-50 disabled:opacity-50"
                      title="Cancel edit"
                    >
                      <XMarkIcon className="h-8 w-8" />
                      <span className="ml-2 font-semibold sr-only">Cancel</span>
                    </button>



    
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}

export default ToDoForm;
