// client/src/pages/Packing.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../services/api';
import ThreeColumnLayout from '../components/ThreeColumnLayout';

import PackingForm from '../components/packingElements/PackingForm';
import SavedPackingList from '../components/packingElements/SavedPackingList';

function Packing({ relocationPlans, selectedPlanId, setSelectedPlanId }) {
  // states
  const [target, setTarget] = useState('relocate');
  const [quantity, setQuantity] = useState(1);
  const [weight, setWeight] = useState(0);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [savedPlans, setSavedPlans] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [editingPack, setEditingPack] = useState(null);
  const [loading, setLoading] = useState(false);

  // pack/item types
  const [packTypes, setPackTypes] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [selectedPackTypeId, setSelectedPackTypeId] = useState('');
  const [selectedItemTypeId, setSelectedItemTypeId] = useState('');

  // dimension fields
  const [packWidth, setPackWidth] = useState(0);
  const [packHeight, setPackHeight] = useState(0);
  const [packLength, setPackLength] = useState(0);
  const [packEmptyWeight, setPackEmptyWeight] = useState(0);

  // item name + user editing
  const [itemName, setItemName] = useState('');
  const [userEditingName, setUserEditingName] = useState(false);

  // notes
  const [notes, setNotes] = useState('');

  // possible targets
  const targetOptions = ['luggage', 'relocate', 'sale', 'storage', 'gift', 'recycle'];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPlanId = queryParams.get('planId') || selectedPlanId;

  // fetch pack/item types
  useEffect(() => {
    async function fetchTypes() {
      try {
        const [ptResp, itResp] = await Promise.all([
          api.get('/pack-types'),
          api.get('/item-types'),
        ]);
        setPackTypes(ptResp.data || []);
        setItemTypes(itResp.data || []);
      } catch (err) {
        console.error('Error fetching pack/item types:', err);
      }
    }
    fetchTypes();
  }, []);

  // retarget
  const handleReTargetPack = async (packId, newTarget) => {
    try {
      const response = await api.put(`/packing/${packId}`, {
        target: newTarget,
      });
      if (response.status === 200) {
        fetchPlans(selectedPlanId);
      }
    } catch (err) {
      console.error('Error retargeting pack:', err);
      // setError or something
    }
  };

  // fetch packing data
  const fetchPlans = async (planId) => {
    if (!planId) {
      setError('No relocation plan selected.');
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/packing');
      const allPacking = response.data || [];

      const filtered = allPacking.filter((p) => p.relocation_plan_id === planId);
      const grouped = filtered.reduce((acc, pack) => {
        const date = pack.relocation_plans?.relocation_date || 'Unknown Date';
        if (!acc[date]) {
          acc[date] = { plans: [] };
        }
        acc[date].plans.push(pack);
        return acc;
      }, {});
      setSavedPlans(grouped);

      const dates = Object.keys(grouped).sort();
      setSelectedDate(dates.length > 0 ? dates[0] : null);
    } catch (err) {
      console.error('Error fetching packing plans:', err);
      setError(err.response?.data?.error || 'Failed to load packing plans');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialPlanId) {
      setSelectedPlanId(initialPlanId);
      fetchPlans(initialPlanId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPlanId]);

  useEffect(() => {
    if (selectedPlanId) {
      fetchPlans(selectedPlanId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPack, selectedPlanId]);

  // handle create
  const handleSubmit = async () => {
    if (quantity <= 0 || weight < 0 || !selectedPlanId) {
      setError('All fields are required and must be valid.');
      return;
    }
    try {
      setLoading(true);
      setError(null);

      const body = {
        name: itemName,
        notes,
        target,
        quantity,
        weight,
        relocation_plan_id: selectedPlanId,
        pack_type_id: selectedPackTypeId || null,
        item_type_id: selectedItemTypeId || null,
        custom_width_cm: packWidth,
        custom_height_cm: packHeight,
        custom_length_cm: packLength,
        custom_weight_kg: packEmptyWeight,
      };

      const response = await api.post('/packing', body);
      if (response.status === 200) {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
        fetchPlans(selectedPlanId);
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.response?.data?.error || 'Failed to save packing data');
    } finally {
      setLoading(false);
    }
  };

  // handle edit
  const handleEditPack = (pack) => {
    setEditingPack(pack);

    setTarget(pack.target || 'relocate');
    setQuantity(pack.quantity || 1);
    setWeight(pack.weight || 0);

    setSelectedPackTypeId(pack.pack_type_id || '');
    setSelectedItemTypeId(pack.item_type_id || '');

    setPackWidth(pack.custom_width_cm || 0);
    setPackHeight(pack.custom_height_cm || 0);
    setPackLength(pack.custom_length_cm || 0);
    setPackEmptyWeight(pack.custom_weight_kg || 0);

    if (pack.name) {
      setItemName(pack.name);
      setUserEditingName(true);
    } else {
      setItemName('');
      setUserEditingName(false);
    }

    setNotes(pack.notes || '');
  };

  // handle save
  const handleSaveEdit = async () => {
    if (!editingPack) return;
    try {
      setLoading(true);
      setError(null);

      const body = {
        name: itemName,
        notes,
        target,
        quantity,
        weight,
        relocation_plan_id: selectedPlanId,
        pack_type_id: selectedPackTypeId || null,
        item_type_id: selectedItemTypeId || null,
        custom_width_cm: packWidth,
        custom_height_cm: packHeight,
        custom_length_cm: packLength,
        custom_weight_kg: packEmptyWeight,
      };

      const response = await api.put(`/packing/${editingPack.id}`, body);
      if (response.status === 200) {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
        fetchPlans(selectedPlanId);
        setEditingPack(null);
      }
    } catch (err) {
      console.error('Error updating packing data:', err);
      setError(err.response?.data?.error || 'Failed to update packing data');
    } finally {
      setLoading(false);
    }
  };

  // handle delete
  const handleDeletePack = async (pack) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.delete(`/packing/${pack.id}`);
      if (response.status === 204) {
        fetchPlans(selectedPlanId);
        if (editingPack && editingPack.id === pack.id) {
          setEditingPack(null);
        }
      }
    } catch (err) {
      console.error('Error deleting pack:', err);
      setError(err.response?.data?.error || 'Failed to delete packing data');
    } finally {
      setLoading(false);
    }
  };

  // handle cancel
  const handleCancelEdit = () => {
    setEditingPack(null);

    setSelectedPackTypeId('');
    setSelectedItemTypeId('');
    setPackWidth(0);
    setPackHeight(0);
    setPackLength(0);
    setPackEmptyWeight(0);
    setItemName('');
    setUserEditingName(false);
    setNotes('');
  };

  // 1) Build the "centerColumn" content
  const centerColumnContent = (
    <>
      {showSuccess && (
        <div className="mb-4 text-green-600 text-center">
          <p className="inline-flex items-center gap-1 font-medium">
            Packing {editingPack ? 'updated' : 'added'} successfully!
          </p>
        </div>
      )}
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

      <PackingForm
        editingPack={editingPack}
        loading={loading}
        error={error}
        showSuccess={showSuccess}
        // states
        itemName={itemName}
        setItemName={setItemName}
        notes={notes}
        setNotes={setNotes}
        target={target}
        setTarget={setTarget}
        quantity={quantity}
        setQuantity={setQuantity}
        weight={weight}
        setWeight={setWeight}
        packWidth={packWidth}
        setPackWidth={setPackWidth}
        packHeight={packHeight}
        setPackHeight={setPackHeight}
        packLength={packLength}
        setPackLength={setPackLength}
        packEmptyWeight={packEmptyWeight}
        setPackEmptyWeight={setPackEmptyWeight}
        selectedPackTypeId={selectedPackTypeId}
        setSelectedPackTypeId={setSelectedPackTypeId}
        selectedItemTypeId={selectedItemTypeId}
        setSelectedItemTypeId={setSelectedItemTypeId}
        packTypes={packTypes}
        itemTypes={itemTypes}
        targetOptions={targetOptions}
        userEditingName={userEditingName}
        setUserEditingName={setUserEditingName}
        handleSubmit={handleSubmit}
        handleSaveEdit={handleSaveEdit}
        handleCancelEdit={handleCancelEdit}
      />
    </>
  );

  // 2) Build the "rightColumn" content
  const rightColumnContent = (
    <>
      {selectedDate && savedPlans[selectedDate] && (
        <SavedPackingList
          savedPlans={savedPlans}
          selectedDate={selectedDate}
          targetOptions={targetOptions}
          handleEditPack={handleEditPack}
          handleDeletePack={handleDeletePack}
          onReTargetPack={handleReTargetPack}
        />
      )}
    </>
  );

  return (
    <ThreeColumnLayout
      title="Packing"
      stepNumber={3}
      relocationPlans={relocationPlans.filter((p) => p.id === selectedPlanId)}
      selectedPlanId={selectedPlanId}
      setSelectedPlanId={setSelectedPlanId}
      redirectPath="/packing"
      centerColumn={centerColumnContent}
      rightColumn={rightColumnContent}
    />
  );
}

export default Packing;
