// client/src/components/ThreeColumnLayout.js
import React from 'react';
import StepHeader from './StepHeader';
import SavedRelocationPlans from './SavedRelocationPlans';

/**
 * A reusable 3-column layout:
 *  - Left:  StepHeader + (optional) leftColumnExtra + SavedRelocationPlans
 *  - Middle: centerColumn
 *  - Right:  rightColumn
 *
 * Props:
 *  - title (string)
 *  - stepNumber (number)
 *  - relocationPlans (array) // on most pages, this is filtered to just the active plan
 *  - selectedPlanId (string)
 *  - setSelectedPlanId (func)
 *  - redirectPath (string)
 *  - leftColumnExtra (React node)
 *  - centerColumn (React node)
 *  - rightColumn (React node)
 */
function ThreeColumnLayout({
  title,
  stepNumber,
  relocationPlans,
  selectedPlanId,
  setSelectedPlanId,
  redirectPath = '/',
  leftColumnExtra = null,
  centerColumn = null,
  rightColumn = null,
}) {
  return (
    <div className="min-h-screen">
      <div className="sm:border mx-auto max-w-7xl bg-transparent rounded-3xl sm:shadow sm:p-6">
        <div className="grid grid-cols-1 lg:grid-cols-6">
          {/* Left Column: Step Header, optional extra, then SavedRelocationPlans */}
          <div className="p-4 lg:col-span-2">
            <StepHeader title={title} stepNumber={stepNumber} />

            {leftColumnExtra}

            <SavedRelocationPlans
              selectedPlanId={selectedPlanId}
              setSelectedPlanId={setSelectedPlanId}
              relocationPlans={relocationPlans}
              redirectPath={redirectPath}
            />
          </div>

          {/* Middle Column */}
          <div className="p-4 lg:col-span-2">
            {centerColumn}
          </div>

          {/* Right Column */}
          <div className="p-4 lg:col-span-2">
            {rightColumn}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeColumnLayout;
