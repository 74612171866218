// client/src/components/Privacy.js
import React from 'react';

function Privacy({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">Privacy Policy</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="text-gray-700">
          <p className="mb-4">
            This is the Privacy Policy for Let Spring. We are committed to protecting your personal information and your right to privacy.
          </p>
          <p className="mb-4">
            When you use our services, you trust us with your information. This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it.
          </p>
          <p className="mb-4">
            <strong>Data Collection:</strong> We collect personal information such as your email address, username, and any other information you provide during registration or use of our services.
          </p>
          <p className="mb-4">
            <strong>Data Usage:</strong> We use your data to provide and improve our services, communicate with you, and ensure the security of our platform.
          </p>
          <p className="mb-4">
            For more details, please contact us at privacy@letspring.com.
          </p>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Privacy;